import React from 'react';
import { defineMessages } from 'react-intl';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { FooterBreadcrumbs } from '@components/FooterBreadcrumbs/FooterBreadcrumbs';
import { GlobalMessages } from '@customTypes/Messages';
import { breadcrumbsHooks } from '@hooks/breadcrumbsHooks/breadcrumbsHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export enum PathKeys {
  AccountBookings = 'accountBookings',
  AccountDetails = 'accountDetails',
  AccountGiftcards = 'accountGiftcards',
  AccountOrders = 'accountOrders',
  AccountOverview = 'accountOverview',
  BalanceCheck = 'balanceCheck',
  Blog = 'blog',
  BlogArticle = 'blogArticle',
  BookingFailed = 'bookingFailed',
  BookingSuccess = 'bookingSuccess',
  Business = 'business',
  Confirmation = 'confirmation',
  Contact = 'contact',
  Cookies = 'cookies',
  Home = 'home',
  Hotels = 'hotels',
  Landing = 'landing',
  Newsletter = 'newsletter',
  NotFound = 'notFound',
  Terms = 'terms',
}

export const messages = defineMessages({
  [PathKeys.Home]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.home',
    defaultMessage: 'Home',
  },
  [`${PathKeys.Home}Url`]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.homeUrl',
    defaultMessage: '/',
  },
  [PathKeys.BalanceCheck]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.balanceCheck',
    defaultMessage: 'Check balance',
  },
  [PathKeys.Hotels]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.hotels',
    defaultMessage: 'Hotels',
  },
  [PathKeys.Contact]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.contact',
    defaultMessage: 'Contact',
  },
  [PathKeys.Terms]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.terms',
    defaultMessage: 'Terms & Conditions',
  },
  [PathKeys.Cookies]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.cookies',
    defaultMessage: 'Cookies & Privacy',
  },
  [PathKeys.NotFound]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.notFound',
    defaultMessage: 'Page not found',
  },
  [PathKeys.Business]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.business',
    defaultMessage: 'Business',
  },
  [PathKeys.BookingSuccess]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.bookingSuccess',
    defaultMessage: 'Booking Success',
  },
  [PathKeys.BookingFailed]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.bookingFailed',
    defaultMessage: 'Booking Failed',
  },
  [`${PathKeys.Landing}`]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.landing',
    defaultMessage: 'Landing',
  },
  [PathKeys.Newsletter]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.newsletter',
    defaultMessage: 'Newsletter subscription',
  },
  [PathKeys.Confirmation]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.confirmation',
    defaultMessage: 'Confirmation',
  },
  [PathKeys.AccountOverview]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.accountOverview',
    defaultMessage: 'Account',
  },
  [`${PathKeys.AccountOverview}Url`]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.accountOverviewUrl',
    defaultMessage: '/account/overview',
  },
  [PathKeys.AccountGiftcards]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.accountGiftcards',
    defaultMessage: 'Giftcards',
  },
  [PathKeys.AccountOrders]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.accountOrders',
    defaultMessage: 'Orders',
  },
  [PathKeys.AccountBookings]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.accountBookings',
    defaultMessage: 'Bookings',
  },
  [PathKeys.AccountDetails]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.accountDetails',
    defaultMessage: 'My data',
  },
  [PathKeys.Blog]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.blog',
    defaultMessage: 'Blog',
  },
  [PathKeys.BlogArticle]: {
    id: 'src.containers.FooterBreadcrumbsContainer.paths.blogArticle',
    defaultMessage: 'Blog article',
  },
});

export const breadcrumbs = {
  balance: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.BalanceCheck],
    },
  ],
  search: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Hotels],
    },
  ],
  hotel: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Hotels],
    },
  ],
  hotels: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Hotels],
    },
  ],
  contact: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Contact],
    },
  ],
  terms: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Terms],
    },
  ],
  cookies: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Cookies],
    },
  ],
  '404': [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.NotFound],
    },
  ],
  business: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Business],
    },
  ],
  bookingSuccess: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.BookingSuccess],
    },
  ],
  bookingFailed: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.BookingFailed],
    },
  ],
  landing: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Landing],
    },
  ],
  newsletter: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Newsletter],
    },
  ],
  confirmation: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Confirmation],
    },
  ],
  accountGiftcards: [
    {
      title: messages[PathKeys.AccountOverview],
      url: messages[`${PathKeys.AccountOverview}Url`],
    },
    {
      title: messages[PathKeys.AccountGiftcards],
    },
  ],
  accountOrders: [
    {
      title: messages[PathKeys.AccountOverview],
      url: messages[`${PathKeys.AccountOverview}Url`],
    },
    {
      title: messages[PathKeys.AccountOrders],
    },
  ],
  accountBookings: [
    {
      title: messages[PathKeys.AccountOverview],
      url: messages[`${PathKeys.AccountOverview}Url`],
    },
    {
      title: messages[PathKeys.AccountBookings],
    },
  ],
  accountDetails: [
    {
      title: messages[PathKeys.AccountOverview],
      url: messages[`${PathKeys.AccountOverview}Url`],
    },
    {
      title: messages[PathKeys.AccountDetails],
    },
  ],
  blog: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.Blog],
    },
  ],
  blogArticle: [
    {
      title: messages[PathKeys.Home],
      url: messages[`${PathKeys.Home}Url`],
    },
    {
      title: messages[PathKeys.BlogArticle],
    },
  ],
};

export type BreadcrumbKeyType = keyof typeof breadcrumbs;

export type Props = {
  breadcrumbKey: BreadcrumbKeyType;
};

export const FooterBreadcrumbsContainer = (props: Props) => {
  const { breadcrumbKey } = props;

  const entity = messageHooks.useSubjectState(GlobalMessages.Entity);
  const entityBreadcrumbs = breadcrumbsHooks.useEntityBreadcrumbs(entity);
  const bcs = breadcrumbsHooks.useStaticBreadcrumbs(breadcrumbs);

  const mergedBreadcrumbs = React.useMemo(() => {
    if (entityBreadcrumbs.length) {
      return entityBreadcrumbs;
    } else {
      return bcs[breadcrumbKey];
    }
  }, [
    bcs,
    breadcrumbKey,
    entityBreadcrumbs,
  ]);

  return (
    <ErrorBoundary>
      <FooterBreadcrumbs breadcrumbs={mergedBreadcrumbs} />
    </ErrorBoundary>
  );
};

FooterBreadcrumbsContainer.displayName = 'FooterBreadcrumbsContainer';
