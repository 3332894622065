import React from 'react';
import { defineMessages } from 'react-intl';

import classnames from 'classnames';

import { ExpediaLogoPlaceholder }
  from '../ExpediaLogoPlaceholder/ExpediaLogoPlaceholder';
import { Logo } from '../Logo/Logo';
import styles from './FullLogo.module.css';

export type Props = {
  className?: string;
  expediaLogoSize: 'large' | 'medium' | 'small' | 'extraSmall';
  hgcLogoSize: 'extraLarge' | 'large' | 'medium' | 'extraMedium' | 'small' | 'extraSmall';
  orientation?: 'vertical' | 'horizontal';
};

const messages = defineMessages({
  poweredByExpedia: {
    id: 'src.components.FullLogo.poweredByExpedia',
    defaultMessage: 'powered by {expediaLogo}',
  },
});

export const FullLogo = React.memo((props: Props) => {
  const { hgcLogoSize, expediaLogoSize, orientation, className } = props;

  return (
    <div className={classnames(styles.fullLogo, className, {
      [styles.horizontal]: orientation === 'horizontal',
      [styles.large]: expediaLogoSize === 'large',
      [styles.extraLarge]: hgcLogoSize === 'extraLarge',
    })}>
      <Logo size={hgcLogoSize} />
      <ExpediaLogoPlaceholder
        message={messages.poweredByExpedia}
        size={expediaLogoSize}
      />
    </div>
  );
});

FullLogo.displayName = 'FullLogo';
