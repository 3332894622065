import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { ImagePresentational } from '../../types/common';
import { Typography } from '../Typography/Typography';
import styles from './TradesBlock.module.css';

const messages = defineMessages({
  title: {
    id: 'src.components.TradesBlock.title',
    defaultMessage: 'Wij vallen onder deze keurmerken',
  },
});

export type Props = {
  trades: ImagePresentational[];
};

export const TradesBlock = React.memo((props: Props) => {
  const {
    trades,
  } = props;

  return (
    <div className={styles.tradesBlock}>
      <Typography variant='h2' className={styles.title}>
        <FormattedMessage {...messages.title} />
      </Typography>

      <div className={styles.tradesList}>
        {trades.map((item, id) => (
          <div key={id} className={styles.tradeItem}>
            <img
              src={item.url[0].href}
              alt={item.alt || ''}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

TradesBlock.displayName = 'TradesBlock';
