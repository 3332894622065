import React from 'react';

import { HomePageV2HeaderBody } from '@components/HomePageV2HeaderBody/HomePageV2HeaderBody';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { entityHooks } from '@hooks/entityHooks/entityHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

import { LayoutExtensionContainer } from '../LayoutExtensionContainer/LayoutExtensionContainer';

export const HomePageV2HeaderBodyContainer = () => {
  const entity = entityHooks.useHomeV2Entity();
  const hotelSearchredirect = navigationHooks.useHotelSearchRedirect();
  const giftCardRedirect = navigationHooks.useGiftCardRedirect();

  return (
    <LayoutExtensionContainer>
      {({ layoutExtension }) => (
        <HomePageV2HeaderBody
          title={entity?.title || ''}
          giftCardRedirect={giftCardRedirect}
          hotelSearchredirect={hotelSearchredirect}
          firstButtonTitle={entity?.heroButtonFirst.title || ''}
          secondButtonTitle={entity?.heroButtonSecond.title || ''}
          buttonsSize={layoutExtension !== LayoutExtension.Mobile ? 'large' : 'medium'}
        />
      )}
    </LayoutExtensionContainer>
  );
};

HomePageV2HeaderBodyContainer.displayName = 'HomePageV2HeaderBodyContainer';
