import React, { Suspense } from 'react';

import classNames from 'classnames';

import { Throbber } from '@components/icons/Throbber/Throbber';
import { ContactBlockContainer }
  from '@containers/ContactBlockContainer/ContactBlockContainer';
import { BreadcrumbKeyType, FooterBreadcrumbsContainer }
  from '@containers/FooterBreadcrumbsContainer/FooterBreadcrumbsContainer';
import { FooterMenuData } from '@containers/FooterContainerV3/FooterContainerV3';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import {
  ComponentIds,
  IconNameLinkBlockPresentational,
  ImagePresentational,
} from '@customTypes/common';
import { domHooks } from '@hooks/domHooks/domHooks';

import { AnimatedExpandable } from '../AnimatedExpandable/AnimatedExpandable';
import { BottomLinks } from '../BottomLinks/BottomLinks';
import { FullLogo } from '../FullLogo/FullLogo';
import { LinksColumn } from '../LinksColumn/LinksColumn';
import { PaymentMethodsV2 } from '../PaymentMethodsV2/PaymentMethodsV2';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import { TradesBlock } from '../TradesBlock/TradesBlock';
import { Typography } from '../Typography/Typography';
import { ChevronDown } from '../icons/ChevronDown/ChevronDown';
import { ChevronUp } from '../icons/ChevronUp/ChevronUp';
import styles from './FooterV3.module.css';

export type Props = {
  breadcrumbKey?: BreadcrumbKeyType;
  footerMenuData: FooterMenuData;
  layoutExtension: LayoutExtension;
  paymentIcons: ImagePresentational[] | null;
  qualityMarks: ImagePresentational[] | null;
  socialLinks: IconNameLinkBlockPresentational[] | null;
  withoutMargin?: boolean;
};

export const FooterV3 = React.memo((props: Props) => {
  const {
    layoutExtension,
    paymentIcons,
    qualityMarks,
    footerMenuData,
    socialLinks,
    breadcrumbKey,
    withoutMargin,
  } = props;

  const { isMobile, isTablet, isDesktop } = domHooks.useLayout(layoutExtension);

  return (
    <div id={ComponentIds.FooterV3} className={classNames(styles.footerV3, {
      [styles.withoutMargin]: withoutMargin,
    })}>
      <Suspense
        fallback={
          <div className={classNames(styles.throbber, { [styles.margin]: withoutMargin })}>
            <Throbber width={40} height={40}/>
          </div>
        }
      >
        {breadcrumbKey && <div className={styles.breadcrumbs}>
          <FooterBreadcrumbsContainer breadcrumbKey={breadcrumbKey}/>
        </div>}

        <ContactBlockContainer />

        <div className={styles.container}>
          {footerMenuData && isDesktop && (
            <div className={classNames(
              styles.wrapper,
              styles.footerMenuWrapper,
            )}>
              {footerMenuData.footerMenuContent.map(column =>
                <LinksColumn
                  key={column.title}
                  links={column.links}
                  title={column.title}
                />)}
            </div>
          )}

          <div className={classNames(
            styles.wrapper,
            styles.footerFullLogo,
          )}>
            <FullLogo
              hgcLogoSize={(isMobile || isTablet) ? 'extraLarge' : 'large'}
              expediaLogoSize='large'
            />
            {socialLinks?.length && (isMobile || isTablet) && (
              <SocialLinks links={socialLinks} />
            )}
            <div className={styles.footerMenuWrapper}>
              {footerMenuData
                && (isMobile || isTablet) && (
                footerMenuData.footerMenuContent.map(column =>
                  <AnimatedExpandable
                    id={column.title}
                    key={column.title}
                    active={footerMenuData.activeColumn === column.title}
                    header={
                      <div
                        className={styles.liksColumnTitle}
                        onClick={() => footerMenuData.setActiveColumn(column.title)}
                      >
                        <Typography variant='h3'>
                          {column.title}
                        </Typography>
                        {
                          footerMenuData.activeColumn === column.title
                            ? <ChevronUp width={10} height={7} />
                            : <ChevronDown width={10} height={7} />
                        }
                      </div>
                    }
                    content={<LinksColumn links={column.links}/>}
                    contentClassName={styles.animatedExpandableContent}
                  />)
              )
              }
            </div>
          </div>
          {
            paymentIcons && (
              <div className={styles.wrapper}>
                <PaymentMethodsV2 paymentMethods={paymentIcons} />
              </div>
            )
          }
          {
            qualityMarks && !!qualityMarks.length && (
              <div className={styles.wrapper}>
                <TradesBlock trades={qualityMarks} />
              </div>
            )
          }
          <div className={classNames(
            styles.wrapper,
            styles.footerBottom,
          )}>
            <BottomLinks />
            {socialLinks?.length && isDesktop && <SocialLinks links={socialLinks} />}
          </div>
        </div>
      </Suspense>
    </div>
  );
});

FooterV3.displayName = 'FooterV3';
