import React from 'react';

import classnames from 'classnames';

import { imageHooks, ImageHeight } from '@nxte-nl/image-hooks';

import { ImagePresentational } from '@customTypes/common';

import { LinkType, Link as LinkComponent } from '../Link/Link';
import { Typography } from '../Typography/Typography';
import styles from './RegionSlide.module.css';

export type Props = {
  active: boolean;
  extended: boolean;
  image: ImagePresentational;
  title: string;
  url: string;
};

export const RegionSlide = React.memo((props: Props) => {
  const {
    active,
    image,
    url,
    title,
    extended,
  } = props;

  const imageHref = imageHooks.useHeight(ImageHeight.S, image.url[0]?.href ?? '');

  return (
    <LinkComponent
      to={url}
      type={LinkType.Href}
    >
      <div
        className={
          classnames(
            styles.regionSlide,
            { [styles.active]: active,
              [styles.notExtended]: !extended,
            },
          )}
        style={{ backgroundImage: `url("${imageHref}")` }}
      >
        <Typography className={styles.title}>{title}</Typography>
      </div>
    </LinkComponent>
  );
});

RegionSlide.displayName = 'RegionSlide';
