import React from 'react';

import classNames from 'classnames';

import { ImagePresentational } from '@customTypes/common';

import { CheckMark } from '../icons/CheckMark/CheckMark';
import { Minus } from '../icons/Minus/Minus';
import styles from './AmenityItem.module.css';

export type AdditionalProps = {
  className?: string;
  iconClassNames?: {
    iconCheckClassName?: string;
    iconClassNameBlock?: string;
    iconImgClassName?: string;
    iconMinusClassName?: string;
  };
  titleClassName?: string;
};

export type Props = {
  icon?: ImagePresentational | null;
  name: string | JSX.Element;
  unavailable?: boolean;
} & AdditionalProps;

export const AmenityItem = React.memo((props: Props) => {
  const { className, iconClassNames, titleClassName, icon, name, unavailable = false } = props;

  return (
    <li className={classNames(styles.amenityItem, className)}>
      <div className={classNames(iconClassNames?.iconClassNameBlock)}>
        {icon ? (
          <img
            src={icon.url[0].href}
            className={classNames(styles.icon, iconClassNames?.iconImgClassName)}
          />
        ) : unavailable ? (
          <Minus
            className={classNames(
              styles.icon,
              styles.iconMinusClassName,
              iconClassNames?.iconMinusClassName,
            )}
            width={13}
          />
        ) : (
          <CheckMark
            className={classNames(styles.icon, iconClassNames?.iconCheckClassName)}
            width={13}
            height={10}
          />
        )}
      </div>
      <div
        className={classNames(styles.titleBlock, titleClassName, {
          [styles.unavailable]: unavailable,
        })}
      >
        {name}
      </div>
    </li>
  );
});

AmenityItem.displayName = 'AmenityItem';
