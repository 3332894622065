import React from 'react';

import classnames from 'classnames';

import { LinkPresentational as Breadcrumb } from '@customTypes/common';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

import { Link, LinkType } from '../Link/Link';
import styles from './FooterBreadcrumbs.module.css';

export type Props = {
  breadcrumbs: Breadcrumb[];
};

export const FooterBreadcrumbs = React.memo((props: Props) => {
  const {
    breadcrumbs,
  } = props;
  const language = navigationHooks.useLanguage();

  return (
    <div className={styles.footerBreadcrumbs}>
      {breadcrumbs.map(({ title, url }, i) => {
        const isLast = i === breadcrumbs.length - 1;

        return (
          <React.Fragment key={`breadcrumb_${i}`}>
            <Link
              type={LinkType.Href}
              to={`/${language}${url}`}
              disabled={isLast}
              className={classnames(
                styles.footerBreadcrumb,
                { [styles.isLast]: isLast },
              )}
            >
              {title}
            </Link>
            {!isLast ? <span className={styles.footerBreadcrumb}>{'>'}</span> : null}
          </React.Fragment>
        );
      })}
    </div>
  );
});

FooterBreadcrumbs.displayName = 'FooterBreadcrumbs';
