import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { textCallbacks } from '@callbacks/textCallbacks/textCallbacks';
import classNames from 'classnames';

import { Typography } from '../Typography/Typography';
import { Address } from '../icons/Address/Address';
import { Newsletter } from '../icons/Newsletter/Newsletter';
import styles from './ContactBlock.module.css';

const messages = defineMessages({
  title: {
    id: 'src.components.ContactBlock.title',
    defaultMessage: 'Couponcode inwisselen',
  },
  newsletterTitle: {
    id: 'src.components.ContactBlock.newsletterTitle',
    defaultMessage: 'Altijd op de hoogte?',
  },
  newsletterBody: {
    id: 'src.components.ContactBlock.newsletterBody',
    defaultMessage: 'Je kunt je aanmelden via <link>deze link</link> voor onze nieuwsbrief.',
  },
  supportTitle: {
    id: 'src.components.ContactBlock.supportTitle',
    defaultMessage: 'Heb je ons nodig?',
  },
  supportBody: {
    id: 'src.components.ContactBlock.supportBody',
    defaultMessage: 'Je kunt ons bereiken via <link>onze klantenservice</link>.',
  },
});

export type Props = {
  classname?: string;
  contactRedirect: string;
  newsletterSubscriptionRedirect: string;
};

export const ContactBlock = React.memo((props: Props) => {
  const {
    contactRedirect,
    newsletterSubscriptionRedirect,
    classname,
  } = props;

  return (
    <div className={classNames(styles.contactBlock, classname)}>
      <div className={styles.container}>
        <Typography variant='h3' className={styles.title}>
          <FormattedMessage {...messages.title} />
        </Typography>

        <div className={styles.group}>
          <div className={styles.contactOption}>
            <Newsletter className={styles.icon} />
            <div>
              <Typography variant='body4' className={styles.contactOptionTitle}>
                <FormattedMessage {...messages.newsletterTitle} />
              </Typography>
              <Typography variant='body4' className={styles.contactOptionDescription}>
                <FormattedMessage {...messages.newsletterBody}
                  values={{
                    link: textCallbacks.wrapLinkTextHandler(
                      newsletterSubscriptionRedirect, {
                        className: styles.link,
                        targetBlank: true,
                      },
                    ),
                  }}
                />
              </Typography>
            </div>
          </div>
          <div className={styles.contactOption}>
            <Address className={styles.icon} />
            <div>
              <Typography variant='body4' className={styles.contactOptionTitle}>
                <FormattedMessage {...messages.supportTitle} />
              </Typography>
              <Typography variant='body4' className={styles.contactOptionDescription}>
                <FormattedMessage {...messages.supportBody}
                  values={{
                    link: textCallbacks.wrapLinkTextHandler(
                      contactRedirect, {
                        className: styles.link,
                        targetBlank: true,
                      },
                    ),
                  }}
                />
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

ContactBlock.displayName = 'ContactBlock';
