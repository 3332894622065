import React from 'react';

import anime from 'animejs';
import classnames from 'classnames';

import { NiceSliderPaginationDot } from '../NiceSliderPaginationDot/NiceSliderPaginationDot';
import { ChevronLeft } from '../icons/ChevronLeft/ChevronLeft';
import { ChevronRight } from '../icons/ChevronRight/ChevronRight';
import styles from './NiceSliderPagination.module.css';

const DOT_WIDTH = 22;
const DOT_HEIGHT = 22;
const DOT_SIDE_MARGIN = 4;
const DOT_SPACING = DOT_WIDTH + (DOT_SIDE_MARGIN * 2);
const MAX_NUMBER_OF_VISIBLE_DOTS = 5;

export const hooks = {
  usePositioning(id: string, activeIdx: number, totalItems: number) {
    const numberOfVisibleDots = React.useMemo(() => totalItems < MAX_NUMBER_OF_VISIBLE_DOTS
      ? totalItems
      : MAX_NUMBER_OF_VISIBLE_DOTS,
    [totalItems]);
    React.useEffect(() => {
      let dotsOffset = -DOT_SPACING * (activeIdx - 2);
      let currentDotOffset = DOT_SPACING * Math.floor(numberOfVisibleDots / 2);
      if (totalItems < MAX_NUMBER_OF_VISIBLE_DOTS) {
        dotsOffset = 0;
        currentDotOffset = DOT_SPACING * activeIdx;
      } else {
        if (activeIdx > totalItems - 3) {
          dotsOffset = -DOT_SPACING * (totalItems - numberOfVisibleDots);
          currentDotOffset = DOT_SPACING * (numberOfVisibleDots - (totalItems % activeIdx));
        } else if (activeIdx < 3) {
          dotsOffset = 0;
          currentDotOffset = DOT_SPACING * activeIdx;
        }
      }
      anime({
        targets: `#${id} .${styles.paginationDots}`,
        translateX: dotsOffset,
        duration: 2000,
      });
      anime({
        targets: `#${id} .${styles.paginationCurrent}`,
        translateX: currentDotOffset,
        scale: 0.8,
        duration: 2000,
      });
    }, [id, activeIdx, totalItems, numberOfVisibleDots]);
  },
  useSideNavigationHandlers(activeIdx: number, setActiveIdx: (idx: number) => void) {
    const handlePrevClick =
      React.useCallback(() => setActiveIdx(activeIdx - 1), [activeIdx, setActiveIdx]);
    const handleNextClick =
      React.useCallback(() => setActiveIdx(activeIdx + 1), [activeIdx, setActiveIdx]);

    return {
      onPrevClick: handlePrevClick,
      onNextClick: handleNextClick,
    };
  },
  useActiveIdx(centered: boolean, index: number, totalItems: number) {
    if (!centered) return index;

    const centerCoeficient = Math.ceil(totalItems / 2) - 1;
    return index + centerCoeficient > totalItems - 1
      ? index + centerCoeficient - totalItems
      : index + centerCoeficient;
  },
};

export type Props = {
  activeIdx: number;
  alwaysVisibleArrows?: boolean;
  centered?: boolean;
  className?: string;
  id: string;
  setActiveIdx: (idx: number, diff?: number) => void;
  totalItems: number;
  withoutArrows?: boolean;
};
export const NiceSliderPagination: React.FC<Props> = React.memo(({
  id,
  className,
  totalItems,
  activeIdx: activeIdxRaw,
  setActiveIdx,
  withoutArrows = false,
  centered = false,
  alwaysVisibleArrows = false,
}) => {
  const dots = React.useMemo(() => Array.from(Array(totalItems)), [totalItems]);
  const activeIdx = hooks.useActiveIdx(centered, activeIdxRaw, totalItems);
  const { onPrevClick, onNextClick } = hooks.useSideNavigationHandlers(activeIdx, setActiveIdx);
  hooks.usePositioning(id, activeIdx, totalItems);

  return (
    <div id={id} className={className}>
      <div>
        <div className={styles.pagination}>
          {
            withoutArrows ? null : (
              <button
                className={classnames(
                  styles.chevronButton,
                  { [styles.disabled]: activeIdx === 0 && !alwaysVisibleArrows },
                )}
                onClick={onPrevClick}
              >
                <ChevronLeft
                  className={styles.chevronIcon}
                  width={9}
                  height={15}
                />
              </button>
            )
          }

          <div className={styles.paginationNavigation} style={{ filter: `url("#filter-${id}")` }}>
            <div className={styles.paginationCurrent} />
            <div className={styles.paginationDots}>
              <svg
                className={styles.paginationDotsSvg}
                xmlns='http://www.w3.org/2000/svg'
                width={DOT_SPACING * totalItems}
                height={DOT_HEIGHT}
                viewBox={`0 0 ${DOT_SPACING * totalItems} ${DOT_HEIGHT}`}
              >
                {
                  dots.map((_, idx) => (
                    <NiceSliderPaginationDot
                      key={`pd_${idx}`}
                      view={
                        (idx + 1 === activeIdx || idx - 1 === activeIdx)
                          ? 'hot'
                          : activeIdx === idx
                            ? 'active'
                            : 'default'
                      }
                      idx={idx}
                      radius={DOT_WIDTH / 2}
                      offset={DOT_SIDE_MARGIN}
                      onClick={(i) => setActiveIdx(i, activeIdx - idx)}
                    >
                      { idx }
                    </NiceSliderPaginationDot>
                  ))
                }
              </svg>
            </div>
          </div>

          {
            withoutArrows ? null : (
              <button
                className={classnames(
                  styles.chevronButton,
                  { [styles.disabled]: activeIdx === totalItems - 1 && !alwaysVisibleArrows },
                )}
                onClick={onNextClick}
              >
                <ChevronRight
                  className={styles.chevronIcon}
                  width={9}
                  height={15}
                />
              </button>
            )
          }
        </div>
      </div>

      <svg className={styles.svgFilter} xmlns='http://www.w3.org/2000/svg' width='800' height='200'>
        <defs>
          <filter id={`filter-${id}`} colorInterpolationFilters='sRGB'>
            <feGaussianBlur in='SourceGraphic' stdDeviation='6' result='blur'/>
            <feColorMatrix
              in='blur'
              mode='matrix'
              values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9'
              result={id}
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
});

NiceSliderPagination.displayName = 'NiceSliderPagination';
