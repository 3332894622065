import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { stateHooks } from '@nxte-nl/state-hooks';

import { ImagePresentational } from '../../types/common';
import { Link, LinkType } from '../Link/Link';
import { Typography } from '../Typography/Typography';
import styles from './PaymentMethodsV2.module.css';

const DEFAULT_NUMBER_OF_ITEMS = 4;

const messages = defineMessages({
  title: {
    id: 'src.components.PaymentMethods.title',
    defaultMessage: 'Beschikbare betaalmethoden',
  },
  additionalPaymentMethods: {
    id: 'src.components.PaymentMethods.additionalPaymentMethods',
    defaultMessage: '+ {paymentMethodsAmount} andere betaalmogelijkheden',
  },
  showAll: {
    id: 'src.components.PaymentMethods.showAll',
    defaultMessage: 'Bekijk alle',
  },
});

export type Props = {
  paymentMethods: ImagePresentational[];
};

export const PaymentMethodsV2 = React.memo((props: Props) => {
  const {
    paymentMethods,
  } = props;

  const {
    value: isAllPaymentMethodsShown,
    setTrue: handleShowAllBtnClick,
  } = stateHooks.useBooleanState();

  const additionalPaymentMethodsCount = paymentMethods.length - DEFAULT_NUMBER_OF_ITEMS;
  const paymentMethodsSliceCount = isAllPaymentMethodsShown
    ? paymentMethods.length : DEFAULT_NUMBER_OF_ITEMS;

  return (
    <div>
      <Typography variant='h3' className={styles.title}>
        <FormattedMessage {...messages.title} />
      </Typography>

      <div className={styles.paymentMethodsList}>

        {paymentMethods.slice(0, paymentMethodsSliceCount).map((item, id) =>
          <div key={id} className={styles.paymentMethod}>
            <img
              src={item.url[0].href}
              alt={item.alt || ''}
            />
          </div> )
        }

        {(!isAllPaymentMethodsShown && paymentMethods.length > DEFAULT_NUMBER_OF_ITEMS) && (
          <div className={styles.showAll}>
            <Typography variant='body1'>
              <FormattedMessage
                {...messages.additionalPaymentMethods}
                values={{ paymentMethodsAmount: additionalPaymentMethodsCount }}
              />
            </Typography>

            <Link
              type={LinkType.Span}
              color='blue'
              onClick={handleShowAllBtnClick}
              className={styles.showAllLink}
            >
              <FormattedMessage {...messages.showAll} />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
});

PaymentMethodsV2.displayName = 'PaymentMethodsV2';
