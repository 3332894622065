import React from 'react';

import { LanguageSwitcherButtonV2 }
  from '@components/LanguageSwitcherButtonV2/LanguageSwitcherButtonV2';
import { GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

export const LanguageSwitcherButtonContainerV2 = React.memo(() => {
  const modalShow =
    messageHooks.useNextWithValue(GlobalMessages.ModalOpen, Modal.LanguageSwitcherV2);

  const language = navigationHooks.useLanguage();

  return (
    <LanguageSwitcherButtonV2
      language={language}
      onClick={modalShow}
    />
  );
});

LanguageSwitcherButtonContainerV2.displayName = 'LanguageSwitcherButtonContainerV2';
