import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import classnames from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';

import { Currency, GiftCardType } from '@customTypes/apiEnums';
import { intlHooks } from '@hooks/intlHooks/intlHooks';

import { CustomRadioButton } from '../CustomRadioButton/CustomRadioButton';
import { Input, InputState } from '../Input/Input';
import { Typography } from '../Typography/Typography';
import styles from './PriceRadioButtonsGroup.module.css';

export const OTHER_BUTTON_ID = 'other-button';

export type RadioButtonType = {
  description?: string | JSX.Element;
  id: string;
  name: string;
  title: string | JSX.Element;
};

export type Props = {
  checked?: string;
  currency: Currency;
  errorState?: boolean;
  isMobile: boolean;
  items: RadioButtonType[];
  maxDigital: number | null;
  maxPhysical: number | null;
  minDigital: number | null;
  minPhysical: number | null;
  onBlur: (amount: number) => void;
  onChangeInput: (value: string | null) => void;
  onClick: (id: string, value?: number) => void;
  recieveOptionSelectedId: GiftCardType;
};

const messages = defineMessages({
  errorText: {
    id: 'src.components.PriceRadioButtonsGroup.errorText',
    defaultMessage: 'Kies een waarde tussen de {minimumValue} en {maximumValue} euro.',
  },
  otherButtonTitle: {
    id: 'src.components.PriceRadioButtonsGroup.otherButtonTitle',
    defaultMessage: 'Anders',
  },
});

export const hooks = {
  useOnChange(
    checked: string,
    onClick: (id: string, value?: number) => void,
    inputValue: string,
    isInputValid: boolean,
  ) {
    const [checkedId, setCheckedId] = React.useState(checked);

    const onChange = React.useCallback(
      (checked: boolean, id: string) => {
        setCheckedId(id);

        if (id === OTHER_BUTTON_ID && isInputValid) {
          onClick(id, Number(inputValue));
        } else {
          onClick(id);
        }
      },
      [inputValue, isInputValid, onClick],
    );

    React.useEffect(() => {
      setCheckedId(checked);
    }, [checked]);

    return {
      checkedId,
      onChange,
    };
  },
  useReadOnlyInput(onChange: (checked: boolean, id: string) => void) {
    const onFocusChange = React.useCallback(() => {
      onChange(true, OTHER_BUTTON_ID);
    }, [onChange]);

    return { onFocusChange };
  },
  useOtherValueInputChange(
    minDigital: number | null,
    maxDigital: number | null,
    maxPhysical: number | null,
    minPhysical: number | null,
    recieveOptionSelectedId: GiftCardType,
    hasError: boolean,
    onChangeInput: (value: string | null) => void,
    currency: Currency,
    setInputValue: (value: string) => void,
    setInputValid: (isValid: boolean) => void,
    setOtherPrevInputValue: (prevValue: number) => void,
  ) {
    const minValue =
      recieveOptionSelectedId === GiftCardType.DIGITAL
        ? intlHooks.useFormatPrice(
          minDigital,
          currency,
        )
        : intlHooks.useFormatPrice(
          minPhysical,
          currency,
        );

    const maxValue =
      recieveOptionSelectedId === GiftCardType.DIGITAL
        ? intlHooks.useFormatPrice(
          maxDigital,
          currency,
        )
        : intlHooks.useFormatPrice(
          maxPhysical,
          currency,
        );

    const handleInputChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.currentTarget.value);
        onChangeInput(null);

        const numberValue = Number(e.currentTarget.value);

        if (recieveOptionSelectedId === GiftCardType.DIGITAL) {
          const isValidTemp =
            numberValue >= (minDigital || 0)
            && numberValue <= (maxDigital || 0);

          setInputValid(isValidTemp);
          isValidTemp ? onChangeInput(e.currentTarget.value) : setOtherPrevInputValue(0);
        } else {
          const isValidTemp =
            numberValue >= (minPhysical || 0)
            && numberValue <= (maxPhysical || 0);

          setInputValid(isValidTemp);
          isValidTemp ? onChangeInput(e.currentTarget.value) : setOtherPrevInputValue(0);
        }
      },
      [
        onChangeInput,
        recieveOptionSelectedId,
        minDigital,
        maxDigital,
        maxPhysical,
        minPhysical,
        setInputValue,
        setInputValid,
        setOtherPrevInputValue,
      ],
    );

    const handleClearButtonCLick = React.useCallback(() => {
      setInputValue('');
      setOtherPrevInputValue(0);
    }, [setInputValue, setOtherPrevInputValue]);

    React.useEffect(() => {
      setInputValid(!hasError);
    }, [hasError, setInputValid]);

    return {
      handleInputChange,
      minValue,
      maxValue,
      handleClearButtonCLick,
    };
  },
  useOnBlur(
    minDigital: number | null,
    maxDigital: number | null,
    maxPhysical: number | null,
    minPhysical: number | null,
    recieveOptionSelectedId: GiftCardType,
    onBlur: (amount: number) => void,
    otherPrevInputValue: number,
    setOtherPrevInputValue: (prevValue: number) => void,
  ) {
    return React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const numberValue = Number(e.currentTarget.value);

      if (recieveOptionSelectedId === GiftCardType.DIGITAL) {
        const isValidTemp =
          numberValue >= (minDigital || 0)
          && numberValue <= (maxDigital || 0);

        if (isValidTemp && otherPrevInputValue !== numberValue) {
          onBlur(numberValue);
          setOtherPrevInputValue(numberValue);
        }
      } else {
        const isValidTemp =
          numberValue >= (minPhysical || 0)
          && numberValue <= (maxPhysical || 0);

        if (isValidTemp && otherPrevInputValue !== numberValue) {
          onBlur(numberValue);
          setOtherPrevInputValue(numberValue);
        }
      }
    }, [
      otherPrevInputValue,
      setOtherPrevInputValue,
      maxDigital,
      maxPhysical,
      minDigital,
      minPhysical,
      onBlur,
      recieveOptionSelectedId,
    ]);
  },
};

export const PriceRadioButtonsGroup = React.memo((props: Props) => {
  const {
    items,
    checked = items[0].id,
    onClick,
    onChangeInput,
    minDigital,
    maxDigital,
    maxPhysical,
    minPhysical,
    recieveOptionSelectedId,
    currency,
    isMobile,
    errorState = false,
    onBlur,
  } = props;

  const [inputValue, setInputValue] = React.useState<string>('');
  const [otherPrevInputValue, setOtherPrevInputValue] = React.useState<number>(0);
  const [isInputValid, setInputValid] = React.useState<boolean>(!errorState);

  const { checkedId, onChange } = hooks.useOnChange(checked, onClick, inputValue, isInputValid);
  const { onFocusChange } = hooks.useReadOnlyInput(onChange);
  const {
    handleInputChange,
    minValue,
    maxValue,
    handleClearButtonCLick,
  } = hooks.useOtherValueInputChange(
    minDigital,
    maxDigital,
    maxPhysical,
    minPhysical,
    recieveOptionSelectedId,
    errorState,
    onChangeInput,
    currency,
    setInputValue,
    setInputValid,
    setOtherPrevInputValue,
  );

  const onInputBlur = hooks.useOnBlur(
    minDigital,
    maxDigital,
    maxPhysical,
    minPhysical,
    recieveOptionSelectedId,
    onBlur,
    otherPrevInputValue,
    setOtherPrevInputValue,
  );

  const buttonRef = React.useRef<HTMLInputElement>(null);
  const intl = useIntl();

  const currencySymbol = React.useMemo(() => getSymbolFromCurrency(currency), [currency]);

  return (
    <div>
      <div className={classnames(styles.priceRadioButtonsGroup, {
        [styles.classNameGroupVariationsBig]:
        items && items.length >= 4,
      })}>
        {items.map((item) => (
          <CustomRadioButton
            key={item.id}
            id={item.id}
            title={item.title}
            description={item.description}
            name={item.name}
            ref={buttonRef}
            checked={checkedId === item.id}
            onChange={onChange}
            classNameItem={styles.priceButton}
            classNameTitle={styles.priceTitle}
            classNameDescription={styles.priceDescription}
          />
        ))}
        {!isMobile && <CustomRadioButton
          key={OTHER_BUTTON_ID}
          id={OTHER_BUTTON_ID}
          title={<FormattedMessage {...messages.otherButtonTitle} />}
          name='Other'
          ref={buttonRef}
          checked={checkedId === OTHER_BUTTON_ID}
          onChange={onChange}
          classNameItem={styles.otherButton}
          classNameTitle={styles.priceTitle}
        />
        }

      </div>
      {checkedId === OTHER_BUTTON_ID && (
        <div>
          <Input
            type='text'
            name='otherPrice'
            state={isInputValid ? InputState.Default : InputState.Error}
            inputSize='large'
            value={inputValue}
            onChange={handleInputChange}
            className={classnames(styles.otherInput, {
              [styles.mobileInputSelected]: checkedId === OTHER_BUTTON_ID,
            })}
            inputClassName={styles.input}
            prefixLabel={currencySymbol || ''}
            noBackgroundPrefix={true}
            withClearButton={true}
            handleClearButtonCLick={handleClearButtonCLick}
            onBlur={onInputBlur}
          />
          {!isInputValid ? (
            <div className={styles.messageWrapper}>
              <Typography variant='body3' className={styles.errorMessage}>
                <FormattedMessage {...messages.errorText} values={{
                  minimumValue: minValue,
                  maximumValue: maxValue,
                }} />
              </Typography>
            </div>) : null}
        </div>
      )}

      {isMobile && checkedId !== OTHER_BUTTON_ID
          && <Input
            type='text'
            name='otherPrice'
            inputSize='large'
            placeholder={intl.formatMessage(messages.otherButtonTitle)}
            className={classnames(styles.otherInput , styles.mobileReadOnlyWrapper)}
            inputClassName={classnames(styles.input , styles.mobileReadOnlyInput)}
            onFocus={onFocusChange}
            readOnly
          />
      }
    </div>
  );
});

PriceRadioButtonsGroup.displayName = 'PriceRadioButtonsGroup';
