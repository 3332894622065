import React from 'react';

import anime from 'animejs';
import classnames from 'classnames';

import styles from './AnimatedExpandable.module.css';

export const hooks = {
  useAnimation(
    id: string,
    duration: number,
    easing: anime.EasingOptions,
    hasContentRefSet: boolean,
    contentRef: React.MutableRefObject<HTMLDivElement | null>,
    animeRef: React.MutableRefObject<anime.AnimeInstance | null>,
    active?: boolean,
  ) {
    const changeBeginCallback = React.useCallback((anim: anime.AnimeInstance) => {
      anim.animatables[0]?.target.classList.remove(styles.noHeight);
    }, []);

    const completeCallback = React.useCallback((anim: anime.AnimeInstance) => {
      if (active) {
        anim.animatables[0]?.target.classList.add(styles.noHeight);
      }
    }, [active]);

    React.useLayoutEffect(() => {
      anime.remove(`#${id}`);
      if (hasContentRefSet && contentRef.current) {
        animeRef.current = anime({
          targets: `#${id}`,
          maxHeight: active ? contentRef.current.scrollHeight : 0,
          duration,
          easing,
          changeBegin: changeBeginCallback,
          complete: completeCallback,
        });
      }
    }, [
      id,
      duration,
      easing,
      active,
      hasContentRefSet,
      contentRef,
      animeRef,
      changeBeginCallback,
      completeCallback,
    ]);

    return {
      height: 0,
      changeBeginCallback,
      completeCallback,
    };
  },
  useHasContent (
    contentRef: React.MutableRefObject<HTMLDivElement | null>,
    setHasContentRefSet: (bol: boolean) => void,
  ) {
    const contentRefCallback = React.useCallback((node: HTMLDivElement | null) => {
      if (node && contentRef) {
        contentRef.current = node;
        setHasContentRefSet(true);
      }
    }, [contentRef, setHasContentRefSet]);

    return {
      contentRefCallback,
    };
  },
};

export type Props = {
  active?: boolean;
  className?: string;
  content: React.ReactNode;
  contentClassName?: string;
  duration?: number;
  easing?: anime.EasingOptions;
  header?: React.ReactNode;
  id: string;
};
export const AnimatedExpandable: React.FC<Props> = React.memo(({
  id: originalId,
  className,
  header = null,
  content,
  active,
  duration = 500,
  easing = 'easeOutQuart',
  contentClassName,
}) => {
  const id = originalId.replace(/\s/g, '_');
  const [hasContentRefSet, setHasContentRefSet] = React.useState<boolean>(false);
  const animeRef = React.useRef<anime.AnimeInstance | null>(null);
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const { height } = hooks.useAnimation(
    id, duration, easing, hasContentRefSet, contentRef, animeRef, active,
  );
  const { contentRefCallback } = hooks.useHasContent(contentRef, setHasContentRefSet);

  return (
    <div className={className}>
      {
        header && (
          <div>
            { header }
          </div>
        )
      }
      <div
        id={id}
        ref={contentRefCallback}
        className={styles.contentWrapper}
        style={{ maxHeight: `${height}px` }}
      >
        <div className={classnames(styles.content, { [styles.shown]: active }, contentClassName)}>
          { content }
        </div>
      </div>
    </div>
  );
});

AnimatedExpandable.displayName = 'AnimatedExpandable';
