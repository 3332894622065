import React from 'react';

import { dataLayerV2Hooks } from '@hooks/dataLayerV2Hooks/dataLayerV2Hooks';

import { Button } from '../Button/Button';
import { Link, LinkType } from '../Link/Link';
import { Typography } from '../Typography/Typography';
import styles from './HomePageV2HeaderBody.module.css';

export type Props = {
  buttonsSize: 'large' | 'medium';
  firstButtonTitle: string;
  giftCardRedirect: () => string;
  hotelSearchredirect: () => string;
  secondButtonTitle: string;
  title: string;
};

export const HomePageV2HeaderBody = React.memo((props: Props) => {
  const {
    title,
    giftCardRedirect,
    hotelSearchredirect,
    firstButtonTitle,
    secondButtonTitle,
    buttonsSize,
  } = props;

  const heroRedirectBtnId = 'hero-redirect-button';
  const trackSelectItem = dataLayerV2Hooks.useTrackSelectItem(heroRedirectBtnId, firstButtonTitle);

  return (
    <div className={styles.homePageV2HeaderBody}>
      <Typography variant='h1' className={styles.title}>
        {title}
      </Typography>
      <div className={styles.buttons}>
        <div className={styles.giveButton}>
          <Link to={giftCardRedirect} type={LinkType.Href}>
            <Button
              id={heroRedirectBtnId}
              size={buttonsSize}
              intent='primary'
              onClick={trackSelectItem}
            >
              {firstButtonTitle}
            </Button>
          </Link>
        </div>
        <div>
          <Link to={hotelSearchredirect} type={LinkType.Href}>
            <Button size={buttonsSize} intent='secondary'>
              {secondButtonTitle}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
});

HomePageV2HeaderBody.displayName = 'HomePageV2HeaderBody';
