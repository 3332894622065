import React from 'react';

import useResizeObserver from '@react-hook/resize-observer';

import { ImagePresentational } from '@customTypes/common';

import styles from './BrandLogosBlock.module.css';

export const hooks = {
  useStates() {
    const [width, setWidth] = React.useState(0);

    return {
      width,
      setWidth,
    };
  },
  useWidth(ref: React.RefObject<HTMLDivElement>) {
    const { width, setWidth } = hooks.useStates();

    useResizeObserver(ref, entry => setWidth(entry.target.clientWidth));

    return width;
  },

  useAnimation(
    ref: React.RefObject<HTMLDivElement>,
    frameWidth: number,
    animateOptions?: KeyframeAnimationOptions,
  ) {
    React.useEffect(() => {
      if (ref.current && frameWidth) {
        const translateX = `${-(frameWidth / 2)}px`;

        const modifiedAnimationOptions = {
          ...animateOptions,
          duration: (Number(animateOptions?.duration) || 750) * frameWidth / 100,
          iterations: animateOptions?.iterations || Infinity,
        };

        ref.current.animate([
          { transform: 'translateX(0)' },
          { transform: `translateX(${translateX})` },
        ], modifiedAnimationOptions);
      }
    }, [ref, frameWidth, animateOptions]);
  },
};

export type Props = {
  animateOptions?: KeyframeAnimationOptions;
  brands: ImagePresentational[];
};

export const BrandLogosBlock = React.memo((props: Props) => {
  const { brands, animateOptions } = props;
  const sliderTrackRef = React.useRef<HTMLDivElement>(null);
  const frameWidth = hooks.useWidth(sliderTrackRef);
  hooks.useAnimation(sliderTrackRef, frameWidth, animateOptions);

  const doubledBrandsArray =
    React.useMemo(() => [...brands, ...brands], [brands]);

  return (
    <div className={styles.brandLogosBlock}>
      <div
        ref={sliderTrackRef}
        className={styles.sliderTrack}
      >
        {doubledBrandsArray.map((logo, idx) => (
          <div className={styles.brandLogo} key={`brand-logo_${idx}`}>
            <img key={`brand-logo_${idx}`} src={logo.url[0].href} alt={logo.alt ?? ''} />
          </div>
        ))}
      </div>
    </div>
  );
});

BrandLogosBlock.displayName = 'BrandLogosBlock';
