import React from 'react';

import { MetadataHelmet, MetaData, UrlType, MetaWrapperType } from '@nxte-nl/metadata-helmet';

import { GlobalMessages } from '@customTypes/Messages';
import { LangCode } from '@customTypes/apiTypes';
import { LanguageCodeMapped } from '@customTypes/common';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import {
  languageMappingInverse,
  navigationHooks,
} from '@hooks/navigationHooks/navigationHooks';
import { serverUtils } from '@utils/serverUtils/serverUtils';

export const EntityMetadataContainer = () => {
  const entity = messageHooks.useSubjectState(GlobalMessages.Entity);
  const language = navigationHooks.useLanguage();
  const getUrl = React.useCallback((url: string | null) =>
    url?.includes(language) ? url : `/${language}${url || '/'}`, [language]);

  const metaData = React.useMemo(() => {
    if (entity?.data?.entity && 'metaData' in entity.data.entity) {
      const {
        canonical,
        hreflangTags,
        twitter,
        og,
      } = entity.data.entity.metaData;

      const helmetMetadata: MetaData = {
        ...entity.data.entity.metaData,
        canonical: {
          type: UrlType.RELATIVE,
          href: getUrl(canonical),
        },
        hreflangTags: hreflangTags?.map(x => ({
          langCode: languageMappingInverse[x.langCode],
          path: {
            type: UrlType.RELATIVE,
            href: `/${languageMappingInverse[x.langCode]}${x.path.href}`,
          },
        })) ?? [],
        twitter: twitter ? {
          ...twitter,
          image: twitter?.image ? {
            type: UrlType.RELATIVE,
            href: twitter.image,
          } : null,
        } : null,
        og: og ? {
          ...og,
          image: og.image ? {
            type: UrlType.RELATIVE,
            href: og.image,
          } : null,
          imageSecure: og.imageSecure ? {
            type: UrlType.RELATIVE,
            href: og.imageSecure,
          } : null,
          url: {
            type: UrlType.RELATIVE,
            href: getUrl(og.url),
          },
          locale: og.locale ? languageMappingInverse[og.locale as LangCode] : null,
        } : null,
        ...(CONFIG.isLive ? null : { noindex: true, nofollow: true }),
      };

      return helmetMetadata;
    }
    return undefined;
  }, [entity, getUrl]);

  return (
    <>
      {serverUtils.isNextBuild() ? null : (
        <div hidden id='metaDataJson'>{JSON.stringify(metaData)}</div>
      )}

      <MetadataHelmet
        basePath={CONFIG.basePath}
        defaultLanguage={LanguageCodeMapped.EnGb}
        language={language}
        metaData={metaData}
        wrapperType={serverUtils.isNextBuild() ? MetaWrapperType.Next : MetaWrapperType.Helmet}
      />
    </>
  );
};
