import React from 'react';

import classNames from 'classnames';

import { imageHooks, ImageSize } from '@nxte-nl/image-hooks';

import { ImagePresentational, LinkPresentational } from '@customTypes/common';
import { dataLayerV2Hooks } from '@hooks/dataLayerV2Hooks/dataLayerV2Hooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

import { AmenityItem } from '../AmenityItem/AmenityItem';
import { Button } from '../Button/Button';
import { Link as LinkComponent, LinkType } from '../Link/Link';
import { Typography } from '../Typography/Typography';
import styles from './GiveGiftcardBlock.module.css';

export type Props = {
  button: LinkPresentational;
  description: string;
  image: ImagePresentational;
  title: string;
  usps: string[];
};

export const GiveGiftcardBlock = React.memo((props: Props) => {
  const {
    title,
    description,
    image,
    usps,
    button,
  } = props;

  const imageHref = imageHooks.useSize(ImageSize.XL, image.url[0].href || '');
  const language = navigationHooks.useLanguage();
  const giveGiftcardRedirectId = 'give-giftcard-redirect-button';
  const trackSelectItem = dataLayerV2Hooks.useTrackSelectItem(
    giveGiftcardRedirectId, button.title, button.url,
  );

  return (
    <div>
      <div className={styles.giveGiftcardBlockInner}>
        <div className={styles.textWrapper}>
          <Typography
            variant='h2'
            className={classNames(styles.title, styles.dangerousHtml)}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Typography
            variant='body4'
            className={classNames(styles.description, styles.dangerousHtml)}
            dangerouslySetInnerHTML={{ __html: description }}
            component='span'
          />
          <div className={styles.usps}>
            {usps.map((usp, id) => (
              <AmenityItem
                key={id}
                className={styles.uspsItem}
                name={usp}
                iconClassNames={{
                  iconCheckClassName: styles.uspsIcon,
                }}
                titleClassName={styles.uspsLabel}
              />
            ))}
          </div>
          <LinkComponent
            id={giveGiftcardRedirectId}
            to={`/${language}${button.url}`}
            type={LinkType.Href}
            onClick={trackSelectItem}
          >
            <Button size='medium' intent='primaryV2' className={styles.redirectButton}>
              {button.title}
            </Button>
          </LinkComponent>
        </div>
        <div className={styles.imageWrapper}>
          <img
            src={imageHref}
            alt={image.alt || 'Giftcard image'}
            className={styles.image}
          />
        </div>
      </div>
    </div>
  );
});

GiveGiftcardBlock.displayName = 'GiveGiftcardBlock';
