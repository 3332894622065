import React from 'react';

import { ExpediaLogoPlaceholder } from '../ExpediaLogoPlaceholder/ExpediaLogoPlaceholder';
import { Typography } from '../Typography/Typography';
import { CheckMark as CheckMarkIcon } from '../icons/CheckMark/CheckMark';
import styles from './InanimatedUspsBanner.module.css';

export type Props = {
  usps: string[];
};

export const InanimatedUspsBanner = React.memo((props: Props) => {
  const { usps } = props;

  return (
    <div
      className={styles.container}
    >
      <div className={styles.uspBannerWrapper}>
        {usps.map((usp, index) => (
          <div key={index} className={styles.uspBannerItem}>
            <CheckMarkIcon className={styles.uspIcon} color='#E160E4' />
            <Typography>
              <ExpediaLogoPlaceholder
                className={styles.uspTextContent}
                message={{
                  id: `formatted-usp-${index}`,
                  defaultMessage: usp,
                }}
              />
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
});

InanimatedUspsBanner.displayName = 'InanimatedUspsBanner';
