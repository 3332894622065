import { LayoutExtension } from './ResponsiveState';

export enum RenderingLayout {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export const layoutExtensionMapping = {
  [RenderingLayout.Mobile]: LayoutExtension.Mobile,
  [RenderingLayout.Desktop]: LayoutExtension.DesktopSmall,
};
