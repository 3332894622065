import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import classNames from 'classnames';

import { StaticImage } from '@nxte-nl/static-image';

import { Button, ButtonFit } from '@components/Button/Button';
import { Link, LinkType } from '@components/Link/Link';
import { Typography } from '@components/Typography/Typography';
import { Close as CloseIcon } from '@components/icons/Close/Close';
import { FeatureToggleHocContainer }
  from '@containers/FeatureToggleHocContainer/FeatureToggleHocContainer';
import { LanguageCodeMapped } from '@customTypes/common';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';
import promoCard from '@images/promoPopup/promo-card.png';
import promoLogo from '@images/promoPopup/promo-logo.png';

import styles from './PromoPopup.module.css';

const messages = defineMessages({
  button: {
    id: 'src.components.PromoPopup.button',
    defaultMessage: 'Ja ik stem op de Hotelgiftcard',
  },
  body: {
    id: 'src.components.PromoPopup.body',
    defaultMessage: 'Stem nu op de Hotelgiftcard en maak kans op leuke prijzen!',
  },
  year: {
    id: 'src.components.PromoPopup.year',
    defaultMessage: '2024',
  },
  link: {
    id: 'src.components.PromoPopup.link',
    defaultMessage: 'https://onderzoekdoen.nl/enq/?f=878mbmalwn',
  },
});

export type Props = {
  showUpTime?: number;
};

export const ANIMATION_TIME = 500;

export const hooks = {
  useAnimatedOpenState(showUpTime: number | undefined) {
    const [isOpen, setIsOpen] = React.useState(!showUpTime);
    const [closeStarted, setCloseStarted] = React.useState(false);

    const handleClose = React.useCallback(() => {
      setCloseStarted(true);
      setTimeout(() => {
        setIsOpen(false);
        setCloseStarted(false);
      }, ANIMATION_TIME);
    }, []);

    React.useEffect(() => {
      if (showUpTime) setTimeout(() => setIsOpen(true), showUpTime - ANIMATION_TIME);
    }, [showUpTime]);

    return { isOpen, handleClose, closeStarted };
  },
};

export const PromoPopup = React.memo((props: Props) => {
  const { showUpTime } = props;

  const language = navigationHooks.useLanguage();
  const intl = useIntl();

  const { isOpen, handleClose, closeStarted } = hooks.useAnimatedOpenState(showUpTime);

  return (
    <FeatureToggleHocContainer>
      {({ promoPopup }) => promoPopup && language === LanguageCodeMapped.NlNl && isOpen ? (
        <div className={classNames(styles.promoPopup, {
          [styles.showUp]: showUpTime,
          [styles.hideOut]: closeStarted,
        })}>
          <CloseIcon
            className={styles.closeIcon}
            width={14}
            height={14}
            color='#a0a0a0'
            onClick={handleClose}
          />
          <StaticImage
            className={styles.promoCard}
            src={promoCard}
            alt='cadeaukaart-Hotelgiftcard'
          />
          <div className={styles.promoLogoWrapper}>
            <StaticImage
              className={styles.promoLogo}
              src={promoLogo}
              alt='Logo-GvhJ-zonder-schaduw'
            />
            <Typography variant='body1' className={styles.year}>
              <FormattedMessage {...messages.year} />
            </Typography>
          </div>
          <Typography variant='body1' className={styles.body}>
            <FormattedMessage {...messages.body} />
          </Typography>
          <Link
            to={intl.formatMessage(messages.link)}
            type={LinkType.ExternalHref}
            target='_blank'
          >
            <Button
              className={styles.button}
              type='button'
              intent='secondaryV2'
              size='medium'
              fit={ButtonFit.Fill}
            >
              <FormattedMessage {...messages.button} />
            </Button>
          </Link>
        </div>
      ) : null}
    </FeatureToggleHocContainer>
  );
});

PromoPopup.displayName = 'PromoPopup';
