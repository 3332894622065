import React from 'react';
import { useIntl } from 'react-intl';

import { PageEntity } from '@customTypes/Messages';
import { Link } from '@customTypes/apiTypes';

export type Message = {
  defaultMessage: string;
  id: string;
};

export type BreadcrumbItem = {
  title: Message;
  url?: Message;
};

export type Breadcrumbs = {
  [key: string]: BreadcrumbItem[];
};

export const breadcrumbsHooks = {
  useStaticBreadcrumbs(breadcrumbs: Breadcrumbs) {
    const intl = useIntl();
    return React.useMemo(
      () =>
        Object.keys(breadcrumbs).reduce<{[path: string]: Link[] }>(
          (prev, key: keyof typeof breadcrumbs) => {
            return {
              ...prev,
              [key]: breadcrumbs[key].map(({ title, url }) => ({
                title: intl.formatMessage(title),
                url: url ? intl.formatMessage(url) : '',
              })),
            };
          },
          {},
        ),
      [breadcrumbs, intl],
    );
  },
  useEntityBreadcrumbs(entity: { data: PageEntity } | null | undefined) {
    return React.useMemo(() => {
      if (entity?.data?.entity && 'breadcrumbs' in entity?.data?.entity) {
        return entity.data.entity.breadcrumbs ?? [];
      }
      return [];
    }, [entity]);
  },
};
