import React from 'react';

import { GlobalMessages } from '@customTypes/Messages';
import { LayoutExtension } from '@customTypes/ResponsiveState';

import { messageHooks } from '../messageHooks/messageHooks';

export const responsiveHooks = {
  useLayoutExtension(
    targetLayout: LayoutExtension,
    action: () => void,
  ) {
    const layoutExtension = messageHooks.useSubjectState(GlobalMessages.LayoutExtension);

    React.useEffect(() => {
      if (layoutExtension === targetLayout) {
        action();
      }
    }, [layoutExtension, targetLayout, action]);

    return layoutExtension;
  },
};
