import React from 'react';

import classnames from 'classnames';

import { LayoutExtension } from '@customTypes/ResponsiveState';
import { domHooks } from '@hooks/domHooks/domHooks';

import { ExpediaLogoPlaceholder } from '../ExpediaLogoPlaceholder/ExpediaLogoPlaceholder';
import { Typography } from '../Typography/Typography';
import { CheckMark as CheckMarkIcon } from '../icons/CheckMark/CheckMark';
import styles from './UspBanner.module.css';

export type Props = {
  layoutExtension: LayoutExtension | null;
  usps: string[];
};

export const UspBanner = React.memo((props: Props) => {
  const { usps, layoutExtension } = props;
  const { isMobile } = domHooks.useLayout(layoutExtension);

  return (
    <div className={styles.container}>
      {layoutExtension !== LayoutExtension.Mobile ? <div className={styles.uspBannerDesktop}>
        {usps.map((usp, index) => (
          <div key={index} className={styles.uspBannerItem}>
            <CheckMarkIcon className={styles.uspIcon} color='#E160E4' />
            <Typography>
              <ExpediaLogoPlaceholder
                className={styles.uspTextContent}
                message={{
                  id: `formatted-usp-${index}`,
                  defaultMessage: usp,
                }}
              />
            </Typography>
          </div>
        ))}
      </div> : null}
      {isMobile ? <>
        {usps.map((usp, index) => (
          <div
            key={index}
            className={classnames(styles.uspBannerItem, styles.uspBannerAnimatedItem)}
            style={{
              animationDelay: index == 0 ? '1s' : `${index * 3 + 1}s`,
            }}
          >
            <div className={styles.uspBannerItemWrapper}>
              <CheckMarkIcon className={styles.uspIcon} color='#E160E4' />
              <Typography className={styles.uspText}>
                <ExpediaLogoPlaceholder
                  className={styles.uspTextContent}
                  message={{
                    id: `formatted-usp-${index}`,
                    defaultMessage: usp,
                  }}
                />
              </Typography>
            </div>
          </div>
        ))}
      </> : null}
    </div>
  );
});

UspBanner.displayName = 'UspBanner';
