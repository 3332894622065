import React from 'react';

export type Props = {
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};

export const LinkedinIcon = React.memo((props: Props) => {
  const {
    width = 24,
    height = 25,
    className,
    onClick,
  } = props;

  const style = {
    minWidth: width,
    minHeight: height,
  };

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path d='M20.9998 19.0098C20.9998 20.3908 19.8808 21.5098 18.4998 21.5098H5.49976C4.11926 21.5098 2.99976 20.3908 2.99976 19.0098V6.00977C2.99976 4.62877 4.11926 3.50977 5.49976 3.50977H18.4998C19.8808 3.50977 20.9998 4.62877 20.9998 6.00977V19.0098Z' fill='#161616'/>
      <path d='M6.00024 10.0098H8.50024V18.5098H6.00024V10.0098ZM7.24274 9.00977H7.22874C6.48274 9.00977 6.00024 8.45377 6.00024 7.75927C6.00024 7.04977 6.49774 6.50977 7.25724 6.50977C8.01774 6.50977 8.48624 7.04977 8.50024 7.75927C8.50024 8.45327 8.01774 9.00977 7.24274 9.00977ZM18.0002 18.5098H15.5002V13.9603C15.5002 12.8613 14.8877 12.1113 13.9042 12.1113C13.1537 12.1113 12.7477 12.6173 12.5507 13.1063C12.4787 13.2813 12.5002 13.7653 12.5002 14.0098V18.5098H10.0002V10.0098H12.5002V11.3178C12.8607 10.7598 13.4252 10.0098 14.8692 10.0098C16.6582 10.0098 17.9997 11.1348 17.9997 13.6468L18.0002 18.5098Z' fill='white'/>
    </svg>
  );
});

LinkedinIcon.displayName = 'LinkedinIcon';
