import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { Typography } from '@components/Typography/Typography';
import { LanguageCodeMapped } from '@customTypes/common';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

import { Link, LinkType } from '../Link/Link';
import styles from './BottomLinks.module.css';

const messages = defineMessages({
  copyright: {
    id: 'src.components.BottomLinks.copyright',
    defaultMessage: 'Copyright © 2023 Hotelgiftcard',
  },
  impressum: {
    id: 'src.components.BottomLinks.impressum',
    defaultMessage: 'Impressum',
  },
  termsAndConditions: {
    id: 'src.components.BottomLinks.termsAndConditions',
    defaultMessage: 'Terms and Conditions',
  },
  cookiesAndPrivacyStatement: {
    id: 'src.components.BottomLinks.cookiesAndPrivacyStatement',
    defaultMessage: 'Cookies & privacy statement',
  },
});

export const BottomLinks = React.memo(() => {
  const language = navigationHooks.useLanguage();
  const termsAndConditionsRedirect = navigationHooks.useTermsAndConditionsRedirect();
  const impressumRedirect = navigationHooks.useImpressumRedirect();
  const imprintRedirect = navigationHooks.useImprintRedirect();
  const cookiesAndPrivacyRedirect = navigationHooks.useCookiesAndPrivacyRedirect();

  const impressumUrl = language === LanguageCodeMapped.DeDe ? impressumRedirect()
    : (language === LanguageCodeMapped.EnDe ? imprintRedirect() : '');

  return (
    <div className={styles.bottomLinks}>
      <Typography
        className={styles.bottomLink}
      >
        <FormattedMessage {...messages.copyright} />
      </Typography>
      {(
        language === LanguageCodeMapped.DeDe || language === LanguageCodeMapped.EnDe
      ) ? (
          <Link
            className={styles.bottomLink}
            to={impressumUrl}
            type={LinkType.Href}
          >
            <FormattedMessage {...messages.impressum} />
          </Link>
        ) : null}
      <Link
        className={styles.bottomLink}
        to={termsAndConditionsRedirect()}
        type={LinkType.Href}
      >
        <FormattedMessage {...messages.termsAndConditions} />
      </Link>
      <Link
        className={styles.bottomLink}
        to={cookiesAndPrivacyRedirect()}
        type={LinkType.Href}
      >
        <FormattedMessage {...messages.cookiesAndPrivacyStatement} />
      </Link>
    </div>
  );
});

BottomLinks.displayName = 'BottomLinks';
