import React from 'react';

import anime from 'animejs';
import classnames from 'classnames';

import { CustomSliderControls } from '../CustomSliderControls/CustomSliderControls';
import { NiceSliderPagination } from '../NiceSliderPagination/NiceSliderPagination';
import { Typography } from '../Typography/Typography';
import styles from './CustomSlider.module.css';

export type SliderState = {
  activeIdx: number;
  extended: boolean;
};

export const hooks = {
  useSliderState(totalItems: number, slideSpacing: number, reversed?: boolean) {
    const [state, setState] =
      React.useState<SliderState>({ activeIdx: reversed ? totalItems - 1 : 0, extended: true });

    const slidesWrapperRefCallback =
      hooks.useSlidesWrapperRefCallback(totalItems, slideSpacing, setState);

    const onSlideChange = React.useCallback((idx: number) =>
      setState((prevState) => ({ ...prevState, activeIdx: idx })), []);

    return { state, setState, slidesWrapperRefCallback, onSlideChange };
  },
  usePositioning(
    state: SliderState,
    totalItems: number,
    slideSpacing: number,
    sliderId: string,
    reversed: boolean,
    contentRef: React.MutableRefObject<HTMLDivElement | null>,
    rafIdRef: React.MutableRefObject<number | null>,
  ) {
    React.useEffect(() => {
      if (contentRef.current) {
        if (rafIdRef.current !== null) {
          cancelAnimationFrame(rafIdRef.current);
        }

        const wrapperWidth = contentRef.current.getBoundingClientRect().width;
        let slidesOffset: number;
        if (reversed) {
          const delta = slideSpacing * (state.activeIdx - (totalItems - 1));
          slidesOffset =
            (-wrapperWidth / 2) - delta + (slideSpacing / 2);
          if (state.activeIdx > totalItems - 2) {
            slidesOffset = 0;
          } else if (state.activeIdx < 1) {
            slidesOffset = (slideSpacing * totalItems) - wrapperWidth;
          }
        } else {
          slidesOffset = (wrapperWidth / 2) - (slideSpacing * state.activeIdx) - (slideSpacing / 2);
          if (state.activeIdx > totalItems - 2) {
            slidesOffset = wrapperWidth - (slideSpacing * totalItems);
          } else if (state.activeIdx < 1) {
            slidesOffset = 0;
          }
        }

        anime({
          targets: `#${sliderId} .${styles.content}`,
          translateX: slidesOffset,
          duration: 500,
          easing: 'easeOutExpo',
        });
      }
    }, [
      state,
      totalItems,
      slideSpacing,
      sliderId,
      reversed,
      contentRef,
      rafIdRef,
    ]);
  },
  useSlidesWrapperRefCallback(
    totalItems: number,
    slideSpacing: number,
    setState: React.Dispatch<React.SetStateAction<SliderState>>,
  ) {
    return React.useCallback((node: HTMLDivElement) => {
      if (node && node.getBoundingClientRect().width > (slideSpacing * totalItems)) {
        setState((prevState) => ({ ...prevState, extended: false }));
      }
    }, [totalItems, slideSpacing, setState]);
  },
  useTranslateStartXState() {
    return React.useState(0);
  },
  useTouchStartXState() {
    return React.useState(0);
  },
  useTouchStartYState() {
    return React.useState(0);
  },
  useSwipableValue() {
    return React.useRef(false);
  },
  useSwipeGestureHandlers(
    contentRef: React.MutableRefObject<HTMLDivElement | null>,
    rafIdRef: React.MutableRefObject<number | null>,
    sliderId: string,
    slideWidth: number,
    totalItems: number,
    state: SliderState,
    setState: React.Dispatch<React.SetStateAction<SliderState>>,
  ) {
    const [translateStartX, setTranslateStartX] = hooks.useTranslateStartXState();
    const [touchStartX, setTouchStartX] = hooks.useTouchStartXState();
    const [touchStartY, setTouchStartY] = hooks.useTouchStartYState();
    const swipableRef = hooks.useSwipableValue();

    const handleStart = React.useCallback((x: number, y: number) => {
      if (!contentRef.current) {
        return;
      }
      anime.remove(`#${sliderId} .${styles.content}`);
      const transformStyle =
        window.getComputedStyle(contentRef.current).getPropertyValue('transform');
      const currentTranslateX = new DOMMatrixReadOnly(transformStyle).e;
      setTouchStartX(x);
      setTouchStartY(y);
      setTranslateStartX(currentTranslateX);
    }, [contentRef, sliderId, setTouchStartX, setTouchStartY, setTranslateStartX]);

    const handleMove = React.useCallback((x: number, y: number) => {
      if (!contentRef.current) {
        return;
      }

      const xDiff = touchStartX - x;
      const yDiff = touchStartY - y;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflowY = 'hidden';
      } else {
        document.documentElement.style.overflow = 'visible';
        document.body.style.overflowY = 'visible';
      }

      const swipeLength = x - touchStartX;

      rafIdRef.current = requestAnimationFrame(() => {
        if (contentRef.current) {
          contentRef.current.style.transform = `translateX(${translateStartX + swipeLength}px)`;
        }
      });
    }, [touchStartX, touchStartY, translateStartX, contentRef, rafIdRef]);

    const handleEnd = React.useCallback((x: number) => {
      const swipeLength = touchStartX - x;
      const swipeThreshold = slideWidth * (swipeLength < 0 ? -0.3 : 0.3);
      const slideIndexOffset = Math.round((swipeLength + swipeThreshold) / slideWidth);
      const relativePosition = state.activeIdx + slideIndexOffset;
      const slideToGo = relativePosition < 0
        ? 0
        : (relativePosition > totalItems - 1)
          ? totalItems - 1
          : relativePosition;
      document.documentElement.style.overflow = 'visible';
      document.body.style.overflowY = 'visible';

      setState((prevState) => ({ ...prevState, activeIdx: slideToGo }));
    }, [touchStartX, slideWidth, totalItems, state.activeIdx, setState]);

    const onTouchStart = React.useCallback((e: React.TouchEvent) =>
      state.extended && handleStart(e.changedTouches[0].pageX, e.changedTouches[0].pageY),
    [state.extended, handleStart]);
    const onTouchMove = React.useCallback((e: React.TouchEvent) =>
      state.extended && handleMove(e.changedTouches[0].pageX, e.changedTouches[0].pageY),
    [state.extended, handleMove]);
    const onTouchEnd = React.useCallback((e: React.TouchEvent) =>
      state.extended && handleEnd(e.changedTouches[0].pageX),
    [state.extended, handleEnd]);
    const onMouseDown = React.useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      swipableRef.current = true;
      state.extended && handleStart(e.clientX, e.clientY);
    }, [swipableRef, state.extended, handleStart]);
    const onMouseMove = React.useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      swipableRef.current && state.extended && handleMove(e.clientX, e.clientY);
    }, [swipableRef, state.extended, handleMove]);
    const onMouseUp = React.useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      swipableRef.current && state.extended && handleEnd(e.clientX);
      swipableRef.current = false;
    }, [swipableRef, state.extended, handleEnd]);
    const onMouseLeave = React.useCallback(onMouseUp, [onMouseUp]);

    return {
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      onMouseDown,
      onMouseMove,
      onMouseUp,
      onMouseLeave,
    };
  },
};

export type Props = {
  children: (activeIdx: number, extended: boolean) => React.ReactNode;
  description: string;
  isDesktop: boolean;
  mobileTextAlignLeft?: boolean;
  reversed?: boolean;
  slideSpacing: number;
  sliderId: string;
  title: string;
  totalItems: number;
};

export const CustomSlider = React.memo((props: Props) => {
  const {
    totalItems,
    slideSpacing,
    children,
    isDesktop,
    title,
    description,
    reversed = false,
    sliderId,
    mobileTextAlignLeft,
  } = props;

  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const rafIdRef = React.useRef<number | null>(null);

  const {
    state,
    setState,
    slidesWrapperRefCallback,
    onSlideChange,
  } = hooks.useSliderState(totalItems, slideSpacing, reversed);
  hooks.usePositioning(state, totalItems, slideSpacing, sliderId, reversed, contentRef, rafIdRef);
  const {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseLeave,
  } = hooks.useSwipeGestureHandlers(
    contentRef,
    rafIdRef,
    sliderId,
    slideSpacing,
    totalItems,
    state,
    setState,
  );

  return (
    <>
      <div className={classnames(styles.sideHelper, { [styles.opaque]: !reversed })} />
      <div
        className={classnames(styles.customSliderWrapper, {
          [styles.reversed]: reversed,
        })}
      >
        {
          !reversed ? (
            <div className={styles.controls}>
              <Typography
                className={classnames(styles.title, { [styles.leftAlign]: mobileTextAlignLeft })}
                variant='h2'>
                {title}
              </Typography>
              <Typography
                className={classnames(
                  styles.description, { [styles.leftAlign]: mobileTextAlignLeft },
                )}>
                <span dangerouslySetInnerHTML={{ __html: description }} />
              </Typography>
              {
                isDesktop && state.extended ? (
                  <CustomSliderControls
                    totalItems={totalItems}
                    activeIdx={state.activeIdx}
                    setActiveIdx={onSlideChange}
                  />
                ) : null
              }
            </div>
          ) : null
        }
        <div id={sliderId} className={styles.customSlider}>
          <div className={styles.slides} ref={slidesWrapperRefCallback}>
            <div
              className={classnames(styles.content, {
                [styles.extended]: state.extended,
                [styles.reversed]: reversed,
              })}
              ref={contentRef}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
              onMouseDown={onMouseDown}
              onMouseMove={onMouseMove}
              onMouseUp={onMouseUp}
              onMouseLeave={onMouseLeave}
            >
              { children(state.activeIdx, state.extended) }
            </div>
          </div>

          {
            !isDesktop && state.extended ? (
              <NiceSliderPagination
                id={`${sliderId}-pagination`}
                totalItems={totalItems}
                activeIdx={state.activeIdx}
                setActiveIdx={onSlideChange}
              />
            ) : null
          }
        </div>
        {
          reversed ? (
            <div className={styles.controls}>
              <Typography className={styles.title} variant='h2'>{title}</Typography>
              <Typography className={styles.description}>
                <span dangerouslySetInnerHTML={{ __html: description }} />
              </Typography>
              {
                isDesktop && state.extended ? (
                  <CustomSliderControls
                    totalItems={totalItems}
                    activeIdx={state.activeIdx}
                    setActiveIdx={onSlideChange}
                  />
                ) : null
              }
            </div>
          ) : null
        }
      </div>
      <div className={classnames(styles.sideHelper, { [styles.opaque]: reversed })} />
    </>
  );
});

CustomSlider.displayName = 'CustomSlider';
