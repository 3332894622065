import React from 'react';

import { InanimatedUspsBanner } from '@components/InanimatedUspsBanner/InanimatedUspsBanner';
import { UspBanner } from '@components/UspBanner/UspBanner';

import { LayoutExtensionContainer } from '../LayoutExtensionContainer/LayoutExtensionContainer';

export type Props = {
  usps: string[];
  withAnimation?: boolean;
};

export const UspBannerContainer = React.memo((props: Props) => {
  const { usps, withAnimation = true } = props;

  return (
    <>
      {withAnimation
        ? <LayoutExtensionContainer>
          {({ layoutExtension }) => (
            <UspBanner
              usps={usps}
              layoutExtension={layoutExtension}
            />
          )}
        </LayoutExtensionContainer>
        : <InanimatedUspsBanner
          usps={usps}
        />
      }
    </>
  );
});

UspBannerContainer.displayName = 'UspBannerContainer';
