import React from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { Modal } from '@components/Modal/Modal';
import { AccountSwitcherContainer }
  from '@containers/AccountSwitcherContainer/AccountSwitcherContainer';
import { FeatureToggleHocContainer }
  from '@containers/FeatureToggleHocContainer/FeatureToggleHocContainer';
import { GiftCardBasketButtonContainer }
  from '@containers/GiftCardBasketButtonContainer/GiftCardBasketButtonContainer';
import { SubmenuType, NestedSubmenuType }
  from '@containers/HeaderContainerV3/HeaderContainerV3';
import { LanguageSwitcherButtonContainerV2 }
  from '@containers/LanguageSwitcherButtonContainerV2/LanguageSwitcherButtonContainerV2';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { domHooks } from '@hooks/domHooks/domHooks';

import { DropdownV2 } from '../DropdownV2/DropdownV2';
import { FullLogo } from '../FullLogo/FullLogo';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { MobileMenuV3 } from '../MobileMenuV3/MobileMenuV3';
import { Typography } from '../Typography/Typography';
import { MenuIconV2 } from '../icons/MenuIconV2/MenuIconV2';
import styles from './HeaderV3.module.css';

export const hooks = {
  useMenuMappingToProps(menu: NestedSubmenuType | null, isMobile: boolean, isTablet: boolean) {
    return React.useMemo(() => {
      if (!menu) {
        return null;
      }
      const title =
        (isMobile || isTablet) ? (
          <Typography
            variant='body4'
            className={classnames(styles.dropdownTitle, {
              [styles.isActive]: menu.isActive,
            })}
          >
            {menu.title}
          </Typography>
        ) : (
          <div className={styles.dropdownTitleWrapper}>
            <LocalizedLink
              to={menu.redirect}
              className={classnames(styles.link, styles.dropdownTitle, {
                [styles.isActive]: menu.isActive,
              })}
            >
              {menu.title}
            </LocalizedLink>
            {
              menu.submenu.length
                ? <FontAwesomeIcon className={styles.chevronIcon} icon={faChevronDown} />
                : null
            }
          </div>
        );
      const items = menu.submenu.map(({ link, isActive }) => (
        <LocalizedLink
          to={link.url}
          className={classnames(styles.link, { [styles.isActive]: isActive })}
        >
          {link.title}
        </LocalizedLink>
      ));
      return { title, redirect: menu.redirect, items };
    }, [isMobile, isTablet, menu]);
  },
};

export type Props = {
  businessMenu: NestedSubmenuType | null;
  clientServiceMenu: NestedSubmenuType | null;
  hideMobileMenu: () => void;
  layoutExtension: LayoutExtension | null;
  mobileMenuExpanded: boolean;
  modalNodeId: string;
  profileName?: string;
  showMobileMenu: () => void;
  staticPositionHeader?: boolean;
  submenu: SubmenuType[] | null;
};

export const HeaderV3 = React.memo((props: Props) => {
  const {
    mobileMenuExpanded,
    showMobileMenu,
    hideMobileMenu,
    layoutExtension,
    businessMenu,
    clientServiceMenu,
    submenu,
    staticPositionHeader,
    modalNodeId,
  } = props;

  const { isMobile, isTablet, isDesktop } = domHooks.useLayout(layoutExtension);
  const businessMenuProps = hooks.useMenuMappingToProps(businessMenu, isMobile, isTablet);
  const clientServiceMenuProps = hooks.useMenuMappingToProps(clientServiceMenu, isMobile, isTablet);

  return (
    <div className={classnames(styles.headerV3, {
      [styles.staticPositionHeader]: staticPositionHeader,
    })}>
      <div className={styles.wrapper}>
        <div className={styles.lefGroup}>
          {(isMobile || isTablet)
            ? (
              <>
                <div className={styles.burger} onClick={showMobileMenu}>
                  <MenuIconV2
                    width={14}
                    height={10}
                  />
                </div>

                <Modal
                  nodeId={modalNodeId}
                  isOpen={mobileMenuExpanded}
                  onClose={hideMobileMenu}
                  modalClassName={classnames('mobileSidebar', styles.mobileMenuPanel)}
                  closeButtonClassName={styles.mobileMenuCloseButton}
                  footer={
                    <LanguageSwitcherButtonContainerV2 />
                  }
                  footerClassName={styles.mobileMenuPanelFooter}
                  layoutExtension={layoutExtension}
                >
                  <MobileMenuV3
                    submenu={submenu}
                    businessMenuProps={businessMenuProps}
                    clientServiceMenuProps={clientServiceMenuProps}
                    hideMobileMenu={hideMobileMenu}
                  />
                </Modal>
              </>
            ) : null}

          <FullLogo
            className={styles.logo}
            hgcLogoSize={!isMobile ? 'extraMedium' : 'small'}
            expediaLogoSize={!isMobile ? 'small' : 'extraSmall'}
            orientation={!isMobile ? 'horizontal' : 'vertical'}
          />
        </div>
        <div className={styles.rightGroup}>
          {isDesktop ? (
            <>
              {
                businessMenuProps && (
                  <div className={styles.separator}>
                    <DropdownV2
                      dropdownClass={styles.dropdown}
                      {...businessMenuProps}
                    />
                  </div>
                )
              }
              {
                clientServiceMenuProps && (
                  <div className={styles.separator}>
                    <DropdownV2
                      dropdownClass={styles.dropdown}
                      {...clientServiceMenuProps}
                    />
                  </div>
                )
              }
              <div className={styles.separator}>
                <LanguageSwitcherButtonContainerV2 />
              </div>
            </>
          ) : null}
          <FeatureToggleHocContainer>
            {({ accountRelease1 }) =>
              accountRelease1
                ? (
                  <div className={styles.separator}>
                    <AccountSwitcherContainer />
                  </div>
                )
                : null
            }
          </FeatureToggleHocContainer>
          <div className={styles.separator}>
            <GiftCardBasketButtonContainer />
          </div>
        </div>

      </div>
      {submenu && isDesktop
        ? <div className={styles.submenu}>
          <div className={styles.submenuInner}>
            {submenu.map((el, i) => (
              <LocalizedLink
                key={i}
                to={el.link.url}
                className={classnames(styles.submenuLink, {
                  [styles.isActive]: el.isActive,
                  [styles.blogLink]: el.link.url.toLowerCase().includes('/blog'),
                })}
              >
                {el.link.title}
              </LocalizedLink>
            ))}
          </div>
        </div>
        : null
      }
    </div>
  );
});

HeaderV3.displayName = 'HeaderV3';
