import React from 'react';

export type Props = {
  className?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const MenuIconV2 = React.memo((props: Props) => {
  return (
    <svg
      width={props.width || 16}
      height={props.height || 16}
      className={props.className}
      onClick={props.onClick}
      fill='none'
    >
      <path fillRule='evenodd' clipRule='evenodd' d='M12.6 5.79995H1.39998C0.951848 5.79995 0.599976 5.44808 0.599976 4.99995C0.599976 4.55182 0.951848 4.19995 1.39998 4.19995H12.6C13.0481 4.19995 13.4 4.55182 13.4 4.99995C13.4 5.44808 13.0481 5.79995 12.6 5.79995ZM12.6 1.79995H1.39998C0.951848 1.79995 0.599976 1.44808 0.599976 0.999951C0.599976 0.551823 0.951848 0.199951 1.39998 0.199951H12.6C13.0481 0.199951 13.4 0.551823 13.4 0.999951C13.4 1.44808 13.0481 1.79995 12.6 1.79995ZM1.39998 8.19995H12.6C13.0481 8.19995 13.4 8.55182 13.4 8.99995C13.4 9.44808 13.0481 9.79995 12.6 9.79995H1.39998C0.951848 9.79995 0.599976 9.44808 0.599976 8.99995C0.599976 8.55182 0.951848 8.19995 1.39998 8.19995Z' fill='#161616'/>
    </svg>
  );
});

MenuIconV2.displayName = 'MenuIconV2';
