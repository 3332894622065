import React from 'react';

import { ContactBlock } from '@components/ContactBlock/ContactBlock';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

export type Props = {
  classname?: string;
};

export const ContactBlockContainer = (props: Props) => {
  const {
    classname,
  } = props;

  const contactRedirect = navigationHooks.useHeaderContactRedirect();
  const newsletterSubscriptionRedirect = navigationHooks.useNewsletterSubscriptionRedirect();

  return (
    <ContactBlock
      contactRedirect={contactRedirect()}
      newsletterSubscriptionRedirect={newsletterSubscriptionRedirect()}
      classname={classname}
    />
  );
};

ContactBlockContainer.displayName = 'ContactBlockContainer';
