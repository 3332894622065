import React from 'react';

import classnames from 'classnames';

import { FeatureToggleHocContainer }
  from '@containers/FeatureToggleHocContainer/FeatureToggleHocContainer';
import { BreadcrumbKeyType }
  from '@containers/FooterBreadcrumbsContainer/FooterBreadcrumbsContainer';
import { HeaderFooterContainer }
  from '@containers/HeaderFooterContainer/HeaderFooterContainer';
import { HeaderFooterDataContainer }
  from '@containers/HeaderFooterDataContainer/HeaderFooterDataContainer';
import { UserInfoContainer } from '@containers/UserInfoContainer/UserInfoContainer';
import { serverUtils } from '@utils/serverUtils/serverUtils';

import styles from './PageWrapperWithHeaderFooter.module.css';

export type Props = {
  breadcrumbKey?: BreadcrumbKeyType;
  children?: React.ReactNode;
  className?: string;
  clientHeaderFooter?: boolean;
  withoutMargin?: boolean;
};

export const PageWrapperWithHeaderFooter: React.FC<Props> = React.memo((props) => {
  const {
    children,
    className,
    breadcrumbKey,
    withoutMargin,
    clientHeaderFooter,
  } = props;

  return (
    <div className={classnames(styles.pageWrapper, className)}>
      {/* only for react build, for debugging purposes and 404 page */}
      {(!serverUtils.isNextBuild() || clientHeaderFooter) && <HeaderFooterDataContainer/>}

      <FeatureToggleHocContainer>
        {({ accountRelease1 }) =>
          accountRelease1 ? <UserInfoContainer /> : null
        }
      </FeatureToggleHocContainer>

      <HeaderFooterContainer
        withoutMargin={withoutMargin}
        breadcrumbKey={breadcrumbKey}
      >
        {children}
      </HeaderFooterContainer>
    </div>
  );
});

PageWrapperWithHeaderFooter.displayName = 'PageWrapperWithHeaderFooter';
