import React from 'react';

export type Props = {
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};

export const FacebookIcon = React.memo((props: Props) => {
  const {
    width = 24,
    height = 25,
    className,
    onClick,
  } = props;

  const style = {
    minWidth: width,
    minHeight: height,
  };

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path d='M21.9995 12.5098C21.9995 6.98477 17.5245 2.50977 11.9995 2.50977C6.47451 2.50977 1.99951 6.98477 1.99951 12.5098C1.99951 17.5014 5.65785 21.6348 10.4328 22.3848V15.4014H7.89118V12.5098H10.4328V10.3098C10.4328 7.80143 11.9245 6.4181 14.2078 6.4181C15.2995 6.4181 16.4495 6.60977 16.4495 6.60977V9.0681H15.1912C13.9495 9.0681 13.5578 9.8431 13.5578 10.6264V12.5014H16.3328L15.8912 15.3931H13.5578V22.3848C18.3412 21.6348 21.9912 17.5014 21.9912 12.5098H21.9995Z' fill='#161616'/>
      <path d='M15.8909 15.4008L16.3325 12.5091H13.5575V10.6341C13.5575 9.84245 13.9409 9.07578 15.1909 9.07578H16.4492V6.61745C16.4492 6.61745 15.3075 6.42578 14.2075 6.42578C11.9242 6.42578 10.4325 7.80911 10.4325 10.3174V12.5174H7.89087V15.4091H10.4325V22.4008C10.9409 22.4841 11.4659 22.5258 11.9992 22.5258C12.5325 22.5258 13.0492 22.4841 13.5659 22.4008V15.4008H15.8992H15.8909Z' fill='white'/>
    </svg>
  );
});

FacebookIcon.displayName = 'FacebookIcon';
