
import { apolloHooks } from '@nxte-nl/apollo-hooks';

import { GlobalMessages } from '@customTypes/Messages';
import { HeaderFooterData } from '@customTypes/apiCompound';
import { BaseResponse } from '@customTypes/common';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { getHeaderFooterData } from '@queries/getHeaderFooterData.gql';

export const hooks = {
  useGetApiData(onCompleted: (data: BaseResponse<HeaderFooterData>) => void) {
    apolloHooks.useQuery<BaseResponse<HeaderFooterData>>(getHeaderFooterData, { onCompleted });
  },
};
export const HeaderFooterDataContainer = () => {
  const setHeaderFooterData = messageHooks.useNext(GlobalMessages.HeaderFooterData);
  hooks.useGetApiData(setHeaderFooterData);

  return null;
};

HeaderFooterDataContainer.displayName = 'HeaderFooterDataContainer';
