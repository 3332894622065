import React from 'react';

import { StaticImage } from '@nxte-nl/static-image';

import { Size } from '@customTypes/common';

import customMedia from '../../media/media.json';
import { DynamicPicture } from '../DynamicPicture/DynamicPicture';
import styles from './HeroBannerV2.module.css';

export type Props = {
  className?: string;
  desktopJpg: string;
  desktopWebp: [string, string];
  dynamicDesktopImage?: string;
  dynamicImg?: boolean;
  dynamicMobileImage?: string;
  mobileWebp: [string, string];
  size?: Size;
};

export const HeroBannerV2 = React.memo((props: Props) => {
  const {
    desktopWebp: [desktopWebp1x, desktopWebp2x],
    mobileWebp: [mobileWebp1x, mobileWebp2x],
    desktopJpg: desktopJpg2x,
    size,
    className,
    dynamicImg,
    dynamicDesktopImage,
    dynamicMobileImage,
  } = props;

  return (
    <div className={className}>
      {dynamicImg ? (
        <DynamicPicture
          dynamicDesktopImage={dynamicDesktopImage}
          dynamicMobileImage={dynamicMobileImage}
          size={size}
        />
      ) : (
        <picture>
          <source
            srcSet={`${desktopWebp2x} 2880w`}
            media={customMedia['custom-media']['--desktop-large']}
            type='image/webp'
          />
          <source
            srcSet={`${desktopWebp1x} 1440w`}
            media={
              `${customMedia['custom-media']['--tablet']}, 
               ${customMedia['custom-media']['--desktop-small']}`
            }
            type='image/webp'
          />
          <source
            srcSet={`${mobileWebp1x} 320w, ${mobileWebp2x} 640w`}
            media={customMedia['custom-media']['--mobile']}
            type='image/webp'
          />
          <StaticImage
            src={desktopJpg2x}
            alt='Hero Banner'
            className={styles.image}
            height={size ? size.height : '100%'}
          />
        </picture>
      )}
    </div>
  );
});

HeroBannerV2.displayName = 'HeroBannerV2';
