import React from 'react';

import classNames from 'classnames';

import { LayoutExtension } from '@customTypes/ResponsiveState';
import { ImagePresentational, LinkPresentational } from '@customTypes/common';
import { domHooks } from '@hooks/domHooks/domHooks';

import { Link, LinkType } from '../Link/Link';
import { Typography } from '../Typography/Typography';
import styles from './BusinessBlock.module.css';

export type BusinessBlockType = {
  body: string;
  button: LinkPresentational;
  image: ImagePresentational;
  imageMobile: ImagePresentational | null;
  title: string;
};

export type Props = BusinessBlockType & {
  layoutExtension: LayoutExtension | null;
};

export const BusinessBlock = React.memo((props: Props) => {
  const {
    title,
    body,
    button,
    image,
    imageMobile,
    layoutExtension,
  } = props;

  const { isMobile } = domHooks.useLayout(layoutExtension);

  return (
    <div className={styles.businessBlock}>
      <div className={styles.contentWrapper}>
        <Typography
          variant='h2'
          className={classNames(styles.title, styles.dangerousHtml)}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Typography
          variant='body4'
          className={classNames(styles.description, styles.dangerousHtml)}
          dangerouslySetInnerHTML={{ __html: body }}
          component='span'
        />
        <Link
          className={styles.link}
          to={button.url}
          type={LinkType.Href}
        >
          {button.title}
        </Link>
      </div>
      <div className={styles.imagesWrapper}>
        <img
          src={isMobile ? imageMobile?.url[0].href : image.url[0].href}
          alt={isMobile ? imageMobile?.alt || '' : image.alt || ''}
          className={styles.image}
        />
      </div>
    </div>
  );
});

BusinessBlock.displayName = 'BusinessBlock';
