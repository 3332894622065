import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useFloatingNodeId } from '@floating-ui/react';

import { callbackHooks } from '@nxte-nl/callback-hooks';
import { ErrorBoundary } from '@nxte-nl/error-boundary';
import { stateHooks } from '@nxte-nl/state-hooks';

import { HeaderV3 } from '@components/HeaderV3/HeaderV3';
import { LanguageSwitcherModalV2 }
  from '@components/LanguageSwitcherModalV2/LanguageSwitcherModalV2';
import { AccountMessages, GiftCardCheckoutMessages, GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { ImagePresentational, LinkPresentational } from '@customTypes/common';
import { giftCardPageHooks } from '@hooks/giftCardPageHooks/giftCardPageHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';
import { responsiveHooks } from '@hooks/responsiveHooks/responsiveHooks';

import { BasketModalContainer } from '../BasketModalContainer/BasketModalContainer';
import { GiftcardBasketContainer } from '../GiftcardBasketContainer/GiftcardBasketContainer';

const messages = defineMessages({
  businessMenuTitle: {
    id: 'src.components.HeaderContainerV3.businessMenuTitle',
    defaultMessage: 'Zakelijk',
  },
  clientServiceMenuTitle: {
    id: 'src.components.HeaderContainerV3.clientServiceMenuTitle',
    defaultMessage: 'Klantenservice',
  },
});

export const hooks = {
  useHeaderFooterDataState() {
    return messageHooks.useSubjectState(GlobalMessages.HeaderFooterData);
  },
  useHideLanguageSwitcher(
    modalId: string | null,
    hideModal: () => void,
    layoutExtension: LayoutExtension | null,
  ) {
    const prevLayoutExtension = React.useRef<LayoutExtension | null>(layoutExtension);

    const isMobileTablet = React.useCallback((layout: LayoutExtension) =>
      layout === LayoutExtension.Mobile || layout === LayoutExtension.Tablet, []);

    React.useEffect(() => {
      if (modalId === Modal.LanguageSwitcherV2 && prevLayoutExtension.current && layoutExtension) {
        const isPrevMobileOrTablet = isMobileTablet(prevLayoutExtension.current);
        const isCurrMobileOrTablet = isMobileTablet(layoutExtension);

        if (isPrevMobileOrTablet !== isCurrMobileOrTablet) {
          hideModal();
          prevLayoutExtension.current = layoutExtension;
        }
      } else prevLayoutExtension.current = layoutExtension;
    }, [hideModal, layoutExtension, modalId, isMobileTablet]);
  },
};

export type SubmenuType = {
  image: ImagePresentational | null;
  isActive: boolean;
  link: LinkPresentational;
};
export type NestedSubmenuType = {
  isActive?: boolean;
  redirect: string;
  submenu: SubmenuType[];
  title: string;
};

export type Props = {
  staticPositionHeader?: boolean;
};

export const HeaderContainerV3 = React.memo((props: Props) => {
  const { staticPositionHeader } = props;

  const { blogPage } = messageHooks.useSubjectState(GlobalMessages.FeatureToggles);

  const {
    value: mobileMenuExpanded,
    setTrue: showMobileMenu,
    setFalse: hideMobileMenu,
  } = stateHooks.useBooleanState();

  const intl = useIntl();
  const isActive = navigationHooks.useActivePage();

  const modalId = messageHooks.useSubjectState(GlobalMessages.ModalOpen);
  const hideModal = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);
  const giftcardBasketLoading =
    messageHooks.useSubjectState(GiftCardCheckoutMessages.GiftcardBasketLoading);
  const userInfoData = messageHooks.useSubjectState(AccountMessages.UserInfo);

  const layoutExtension = responsiveHooks.useLayoutExtension(
    LayoutExtension.DesktopSmall,
    hideMobileMenu,
  );

  const onGiftcardCheckout = giftCardPageHooks.useOnCheckoutRedirect(hideModal);

  const handleOnModalsClose = callbackHooks.useWrapCallbacks([hideModal, hideMobileMenu]);

  const headerFooterData = hooks.useHeaderFooterDataState();
  const businessRedirect = navigationHooks.useBusinessRedirect();
  const contactRedirect = navigationHooks.useContactRedirect();

  hooks.useHideLanguageSwitcher(modalId, hideModal, layoutExtension);

  const submenu: SubmenuType[] | null =
    React.useMemo(() => headerFooterData?.data.entity.submenu?.map((sm) => ({
      link: {
        title: sm.link.title,
        url: sm.link.url,
      },
      image: sm.image,
      isActive: isActive(sm.link.url),
    })) ?? null, [isActive, headerFooterData]);

  const submenuWithoutBlog: SubmenuType[] | null =
    React.useMemo(() => {
      if (!blogPage || !submenu) return submenu;
      return submenu.filter(item => !item.link.url.toLowerCase().includes('/blog'));
    }, [blogPage, submenu]);

  const businessMenu: NestedSubmenuType | null = React.useMemo(() => {
    if (!headerFooterData?.data.entity.businessMenu) {
      return null;
    }
    const businessSubmenu = headerFooterData.data.entity.businessMenu.map((bm) => ({
      link: {
        title: bm.title,
        url: bm.url,
      },
      image: null,
      isActive: isActive(bm.url),
    }));
    const businessRedirectUrl = businessRedirect();
    return {
      title: intl.formatMessage(messages.businessMenuTitle),
      redirect: businessRedirectUrl,
      isActive: isActive(businessRedirectUrl),
      submenu: businessSubmenu,
    };
  }, [isActive, headerFooterData, businessRedirect, intl]);
  const clientServiceMenu: NestedSubmenuType | null = React.useMemo(() => {
    if (!headerFooterData?.data.entity.clientServiceMenu) {
      return null;
    }
    const businessSubmenu = headerFooterData.data.entity.clientServiceMenu.map((csm) => ({
      link: {
        title: csm.title,
        url: csm.url,
      },
      image: null,
      isActive: isActive(csm.url),
    }));
    const contactRedirectUrl = contactRedirect();
    return {
      title: intl.formatMessage(messages.clientServiceMenuTitle),
      redirect: contactRedirectUrl,
      isActive: isActive(contactRedirectUrl),
      submenu: businessSubmenu,
    };
  }, [isActive, headerFooterData, contactRedirect, intl]);

  const parentId = useFloatingNodeId();
  const childId = useFloatingNodeId(parentId);

  return (
    <>
      <HeaderV3
        mobileMenuExpanded={mobileMenuExpanded}
        showMobileMenu={showMobileMenu}
        hideMobileMenu={handleOnModalsClose}
        layoutExtension={layoutExtension}
        businessMenu={businessMenu}
        clientServiceMenu={clientServiceMenu}
        submenu={submenuWithoutBlog}
        staticPositionHeader={staticPositionHeader}
        profileName={userInfoData?.data.user?.mainProfile.firstName}
        modalNodeId={parentId}
      />
      <ErrorBoundary>
        <GiftcardBasketContainer />
      </ErrorBoundary>
      <BasketModalContainer
        onContinueButtonClick={onGiftcardCheckout}
        continueLoading={giftcardBasketLoading}
        expanded={modalId === Modal.GiftCardBasket}
        withRedirect
      />
      <LanguageSwitcherModalV2
        isOpen={modalId === Modal.LanguageSwitcherV2}
        onCloseLanguageModal={hideModal}
        onCloseModals={handleOnModalsClose}
        withoutOverlay
        modalNodeId={childId}
      />
    </>
  );
});

HeaderContainerV3.displayName = 'HeaderContainerV3';
