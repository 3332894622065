import React from 'react';

export type Props = {
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};

export const TwitterIcon = React.memo((props: Props) => {
  const {
    width = 24,
    height = 25,
    className,
    onClick,
  } = props;

  const style = {
    minWidth: width,
    minHeight: height,
  };

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path d='M20.9995 6.72427C20.338 7.01727 19.6265 7.21277 18.876 7.30527C19.639 6.85227 20.226 6.12977 20.5015 5.27627C19.7875 5.69477 18.9965 6.00227 18.155 6.16427C17.483 5.45177 16.5245 5.00977 15.4625 5.00977C13.4225 5.00977 11.769 6.64877 11.769 8.66977C11.769 8.95577 11.8025 9.23427 11.8655 9.50477C8.79655 9.35077 6.07455 7.89177 4.25355 5.67777C3.93355 6.21877 3.75355 6.85227 3.75355 7.52077C3.75355 8.79127 4.40405 9.90977 5.39605 10.5688C4.79055 10.5503 4.22055 10.3818 3.72155 10.1118C3.72155 10.1228 3.72155 10.1393 3.72155 10.1548C3.72155 11.9303 4.99505 13.4088 6.68305 13.7453C6.37455 13.8298 6.04855 13.8768 5.71255 13.8768C5.47405 13.8768 5.24155 13.8498 5.01655 13.8093C5.48655 15.2603 6.85005 16.3208 8.46555 16.3523C7.20155 17.3323 5.60955 17.9193 3.87855 17.9193C3.57955 17.9193 3.28705 17.9023 2.99805 17.8673C4.63355 18.9028 6.57555 19.5098 8.66005 19.5098C15.4525 19.5098 19.1685 13.9318 19.1685 9.09277C19.1685 8.93427 19.1635 8.77627 19.156 8.62027C19.881 8.10827 20.506 7.46227 20.9995 6.72427Z' fill='#161616'/>
    </svg>
  );
});

TwitterIcon.displayName = 'TwitterIcon';
