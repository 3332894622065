import React from 'react';

import dynamic from 'next/dynamic';

import { imageHooks, ImageSize } from '@nxte-nl/image-hooks';

import { BenefitBlock } from '@components/BenefitBlock/BenefitBlock';
import { BrandLogosBlock } from '@components/BrandLogosBlock/BrandLogosBlock';
import { BusinessBlock } from '@components/BusinessBlock/BusinessBlock';
import { GiveGiftcardBlock } from '@components/GiveGiftcardBlock/GiveGiftcardBlock';
import { HomePageV2 } from '@components/HomePageV2/HomePageV2';
import { PromoPopup } from '@components/PromoPopup/PromoPopup';
import { RegionsSlider } from '@components/RegionsSlider/RegionsSlider';
import { GlobalMessages } from '@customTypes/Messages';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { LanguageCodeMapped } from '@customTypes/common';
import { domHooks } from '@hooks/domHooks/domHooks';
import { entityHooks } from '@hooks/entityHooks/entityHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';
import { serverUtils } from '@utils/serverUtils/serverUtils';

import { LocaleRedirectContainer } from '../LocaleRedirectContainer/LocaleRedirectContainer';

const TrustpilotBlock = serverUtils.isNextBuild()
  ? dynamic(() => import('@components/TrustpilotBlock/TrustpilotBlock'), { ssr: false })
  : React.lazy(() => import('@components/TrustpilotBlock/TrustpilotBlock'));

export type Props = {
  layoutExtension: LayoutExtension;
};

export const hooks = {
  useMobileHeroHeight() {
    const language = navigationHooks.useLanguage();

    let mobileSize = { width: 320, height: 540 };

    switch (language) {
      case LanguageCodeMapped.EsEs:
      case LanguageCodeMapped.ItIt:
      case LanguageCodeMapped.FrBe:
        mobileSize = { width: 320, height: 590 };
        break;
      case LanguageCodeMapped.FrFr:
        mobileSize = { width: 320, height: 620 };
        break;
      default:
        mobileSize = { width: 320, height: 540 };
    }

    return mobileSize;
  },
};

export const HomePageV2Container = (props: Props) => {
  const { layoutExtension } = props;
  const entityLoading = messageHooks.useSubjectState(GlobalMessages.EntityLoading);
  const entity = entityHooks.useHomeV2Entity();

  const desktopHeroImageHref =
    imageHooks.useSize(ImageSize.XXL, entity?.heroImage?.url[0].href ?? '');
  const mobileHeroImageHref =
    imageHooks.useSize(ImageSize.XL, entity?.heroImageMobile?.url[0].href ?? '');

  const { isDesktop } = domHooks.useLayout(layoutExtension);

  const desktopSize = { width: 1440, height: 752 };
  const mobileSize = hooks.useMobileHeroHeight();

  return (
    <>
      <LocaleRedirectContainer />
      <HomePageV2
        loading={entityLoading || false}
        desktopHeroImageUrl={desktopHeroImageHref}
        mobileHeroImageUrl={mobileHeroImageHref}
        desktopSize={desktopSize}
        mobileSize={mobileSize}
        usps={entity?.heroUsp || null}
        brandLogosBlock={
          entity && entity?.brandsFirstRow.length
            ? (
              <>
                <BrandLogosBlock
                  brands={entity.brandsFirstRow}
                  animateOptions={{ duration: 650 }}
                />
                <BrandLogosBlock
                  brands={entity.brandsSecondRow}
                />
              </>
            ) : null
        }
        contentBlockFirst={
          entity?.contentBlockFirst_v2
            ? (
              <BenefitBlock
                title={entity.contentBlockFirst_v2.title}
                description={entity.contentBlockFirst_v2.body}
                image={entity.contentBlockFirst_v2.image}
                imageMobile={entity.contentBlockFirst_v2.mobileImage}
                usps={entity.contentBlockFirst_v2.usps}
                button={entity.contentBlockFirst_v2.button}
                layoutExtension={layoutExtension}
              />
            ) : null
        }
        businessBlock={
          entity?.businessBlock_v2
            ? (
              <BusinessBlock
                title={entity.businessBlock_v2.title}
                body={entity.businessBlock_v2.body}
                button={entity.businessBlock_v2.button}
                image={entity.businessBlock_v2.image}
                imageMobile={entity.businessBlock_v2.mobileImage || null}
                layoutExtension={layoutExtension}
              />
            ) : null
        }
        contentBlockSecond={
          entity?.contentBlockSecond_v2
            ? <GiveGiftcardBlock
              title={entity.contentBlockSecond_v2.title}
              description={entity.contentBlockSecond_v2.body}
              image={entity.contentBlockSecond_v2.image}
              usps={entity.contentBlockSecond_v2.usps}
              button={entity.contentBlockSecond_v2.button}
            /> : null
        }
        firstRegionsSlider={
          entity && entity.regionsBlockFirst && layoutExtension ? (
            <RegionsSlider
              items={entity.regionsBlockFirst.regions}
              title={entity.regionsBlockFirst.title}
              description={entity.regionsBlockFirst.body}
              layoutExtension={layoutExtension}
              sliderId='first-regions-slider'
              isDesktop={isDesktop}
            />
          ) : null
        }
        secondRegionsSlider={
          entity && entity.regionsBlockSecond && layoutExtension ? (
            <RegionsSlider
              reversed={isDesktop}
              items={entity.regionsBlockSecond.regions}
              title={entity.regionsBlockSecond.title}
              description={entity.regionsBlockSecond.body}
              layoutExtension={layoutExtension}
              sliderId='second-regions-slider'
              isDesktop={isDesktop}
            />
          ) : null
        }
        trustpilotBlock={
          entity && entity.showTrustpilotReviews
            ? <TrustpilotBlock showTitle={true} />
            : null
        }
        weights={entity?.weights || null}
      />
      <PromoPopup showUpTime={3000} />
    </>
  );
};

HomePageV2Container.displayName = 'HomePageV2Container';

export default HomePageV2Container;
