import React from 'react';

import { apolloHooks } from '@nxte-nl/apollo-hooks';
import { callbackHooks } from '@nxte-nl/callback-hooks';

import { GiftCardPackageOptions } from '@components/GiftCardPageV2Body/GiftCardPageV2Body';
import { SliderItem } from '@components/PackageSlider/PackageSlider';
import { OTHER_BUTTON_ID } from '@components/PriceRadioButtonsGroup/PriceRadioButtonsGroup';
import { ExtraItem } from '@containers/ExtrasModalContainer/ExtrasModalContainer';
import {
  DataLayerEcommerceGiftCardProductV3,
  DataLayerGiftCardProductV2,
} from '@customTypes/DataLayer';
import { ApiGiftCardOrderResponse, BaseVariables } from '@customTypes/apiCompound';
import { GiftCardType, OrderFlow } from '@customTypes/apiEnums';
import { MutationaddGiftCardPackageArgs } from '@customTypes/apiTypes';
import { dataMappingHooks } from '@hooks/dataMappingHooks/dataMappingHooks';
import { addGiftCardPackage } from '@mutations/addGiftCardPackage.gql';

import { navigationHooks } from '../navigationHooks/navigationHooks';

export const giftCardPageHooks = {
  useOnCheckoutRedirect(hideBasketModal?: () => void) {
    const checkoutRedirect = navigationHooks.useGiftcardCheckoutRedirect();
    return React.useCallback(() => {
      hideBasketModal && hideBasketModal();
      window.location.href = checkoutRedirect();
    }, [checkoutRedirect, hideBasketModal]);
  },
  useShowBasketModalWhenNoError(showBasketModal: () => void) {
    return React
      .useCallback((isError: boolean) => !isError && showBasketModal(), [showBasketModal]);
  },
  useGiftcardPackage(
    setOrder: (data: ApiGiftCardOrderResponse) => void,
    showBasketModal: () => void,
    handleResetAllOptions: () => void,
    trackAddToCartV2: (ecommerce: DataLayerEcommerceGiftCardProductV3 | null) => void,
    clearDataLayer: () => void,
  ) {
    const showBasketModalWhenNoError = giftCardPageHooks
      .useShowBasketModalWhenNoError(showBasketModal);
    const mutationCallback = callbackHooks.useWrapCallbacks<boolean>([
      handleResetAllOptions, showBasketModalWhenNoError,
    ]);

    const [mutate, { loading }] = apolloHooks.useMutation<ApiGiftCardOrderResponse,
        BaseVariables<MutationaddGiftCardPackageArgs>>(addGiftCardPackage, {
          onCompleted: (data) => {
            const packages = dataMappingHooks.useFlatPackagesFromOrder(data);

            const { mappedItemsV2: items } = dataMappingHooks.useMapAllShipmentsV2(
              packages.length ? [packages[packages.length - 1]] : [],
            );
            const tax = data?.data.order?.orderTaxes.reduce((prev, cur) =>
              prev + cur.taxAmount.value, 0) ?? 0;
            const value = items?.reduce((prev, curr) =>
              prev + (Number(curr.price) * curr.quantity), 0) ?? 0;
            if (items && data.data.order?.grandTotal) {
              clearDataLayer();
              trackAddToCartV2({
                currency: data.data.order?.grandTotal?.currency,
                value: value - tax,
                items,
              });
            }
            mutationCallback(!!data.data.errors.length);
            if (data) setOrder(data);
          },
          context: { orderFlow: OrderFlow.giftcard_order },
        });

    const onGiftcardPackage = React.useCallback((options: GiftCardPackageOptions) => {
      if (!options.personalMessageText && options.showTextArea) {
        options.setErrorStatePersonalMessage(true);

        if (options.giftCardSKU === OTHER_BUTTON_ID && !options.variationOtherValue) {
          options.setErrorStateVariations(true);
        }

        return;
      }

      if (options.giftCardSKU === OTHER_BUTTON_ID && !options.variationOtherValue) {
        options.setErrorStateVariations(true);
        return;
      }

      if (options.giftCardSKU) {
        mutate({ variables: {
          giftCardPackageData: {
            quantity: options.quantity,
            giftCardSKU:
              options.giftCardSKU === OTHER_BUTTON_ID && options.variationOtherValue
                ? options.variationOtherValue
                : options.giftCardSKU,
            giftPackingSKU: options.giftPackingSKU,
            wrapperSKU:
              options.recieveOptionSelectedId !== GiftCardType.DIGITAL
                ? options.wrapperSKU
                : null,
            personalMessageSKU: options.personalMessageSKU,
            personalMessageText: options.personalMessageText,
            extraProductsSKUs: options.extraProductsSKUs,
          },
          packageID: null,
        } });
      }
    }, [mutate]);

    return {
      onGiftcardPackage,
      loading,
    };
  },
  useOnRecieveOptionChange(
    defaultRecieveType: GiftCardType,
    handleResetAllOptions: () => void,
    trackSelectVariantShipping: (item: GiftCardType) => void,
  ) {
    const [recieveOptionSelectedId, setRecieveOptionSelectedId] =
      React.useState<GiftCardType>(defaultRecieveType);

    // only while physicalDeliveryFr FF present
    React.useEffect(() => {
      setRecieveOptionSelectedId(defaultRecieveType);
    }, [defaultRecieveType]);

    const handleRecieveOptionChange = React.useCallback((recieveItemId: GiftCardType) => {
      setRecieveOptionSelectedId(recieveItemId);
      trackSelectVariantShipping(recieveItemId);

      handleResetAllOptions();
    }, [handleResetAllOptions, trackSelectVariantShipping]);

    return { recieveOptionSelectedId, handleRecieveOptionChange };
  },
  useHandleGiftcardPackage(
    options: GiftCardPackageOptions,
    onGiftcardPackage: (options: GiftCardPackageOptions) => void,
    trackAddToCart: () => void,
  ) {
    const handleGiftcardPackage = React.useCallback(() => {
      onGiftcardPackage(options);
      trackAddToCart();
    }, [
      onGiftcardPackage,
      options,
      trackAddToCart,
    ]);

    return { handleGiftcardPackage };
  },
  usePersonalNote(
    personalNote: string | null,
    setPersonalNote: (value: string | null) => void,
    setErrorStatePersonalMessage: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    const handleTextChange = React.useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setErrorStatePersonalMessage(false);
        setPersonalNote(e.target.value);
      },
      [setErrorStatePersonalMessage, setPersonalNote],
    );

    return { personalNote, handleTextChange, setPersonalNote };
  },
  useResetAllOptions(
    setShowTextArea: (bol: boolean) => void,
    setPersonalNote: (text: string | null) => void,
    setExtasSelectedItems: (items: ExtraItem[]) => void,
    handlePackageChange: (wrap: SliderItem) => void,
    setAdditionalPackingValue: (value: boolean) => void,
    defaultWrapper: SliderItem,
  ) {
    const handleResetAllOptions = React.useCallback(() => {
      setShowTextArea(false);
      setPersonalNote(null);
      setExtasSelectedItems([]);
      setAdditionalPackingValue(false);
      handlePackageChange(defaultWrapper);
    }, [
      setShowTextArea,
      setPersonalNote,
      setExtasSelectedItems,
      handlePackageChange,
      setAdditionalPackingValue,
      defaultWrapper,
    ]);

    return { handleResetAllOptions };
  },
  useItemToTrackState(itemsToTrack: DataLayerGiftCardProductV2[] | null) {
    return React.useState<DataLayerGiftCardProductV2[] | null>(itemsToTrack);
  },
  useOnPackageSelect(
    setPackageSelected: (wrap: SliderItem) => void,
    trackSelectVariantPackage: (title: string) => void,
  ) {
    return React.useCallback((wrap: SliderItem) => {
      setPackageSelected(wrap);
      trackSelectVariantPackage(String(wrap.title));
    }, [
      setPackageSelected,
      trackSelectVariantPackage,
    ]);
  },
};
