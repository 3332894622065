import React from 'react';

import classnames from 'classnames';

import { Typography } from '../Typography/Typography';
import styles from './CustomRadioButton.module.css';

export const hooks = {
  useHandleClick(id: string, checked: boolean, onClick?: (checked: boolean, id: string) => void) {
    return React.useCallback(() => {
      if (!checked) {
        onClick && onClick(checked, id);
      }
    }, [onClick, id, checked]);
  },
};

export type Props = {
  checked: boolean;
  classNameDescription?: string;
  classNameItem?: string;
  classNameTitle?: string;
  description?: string | JSX.Element;
  id: string;
  name: string;
  onChange?: (cheched: boolean, id: string) => void;
  title: string | JSX.Element;
};

export const CustomRadioButton = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      id,
      title,
      description,
      onChange,
      checked,
      name,
      classNameItem,
      classNameTitle,
      classNameDescription,
    } = props;

    const handleClick = hooks.useHandleClick(id, checked, onChange);

    return (
      <div className={classnames(styles.customRadioButton, classNameItem)}>
        <input
          ref={ref}
          type='radio'
          id={id}
          name={name}
          value={id}
          onChange={handleClick}
          checked={checked}
          className={styles.input}
        />

        <label htmlFor={id} className={styles.customRadioButtonLabel}>
          <Typography
            variant='body1'
            className={classnames(styles.customRadioButtonLabelTitle, classNameTitle)}
          >
            {title}
          </Typography>
          {description && (
            <Typography
              variant='body4'
              className={classnames(
                styles.customRadioButtonLabelDescription,
                classNameDescription,
              )}
            >
              {description}
            </Typography>
          )}
        </label>
      </div>
    );
  },
);

CustomRadioButton.displayName = 'CustomRadioButton';
