import React from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';

import { LanguageCodeMapped } from '@customTypes/common';

import { languageCountryMapping } from '../CountrySelect/CountrySelect';
import styles from './LanguageSwitcherButtonV2.module.css';

export type Props = {
  language: LanguageCodeMapped;
  onClick?: () => void;
};

export const LanguageSwitcherButtonV2: React.FC<Props> = React.memo(({
  language,
  onClick,
}) => {
  const flagCode = languageCountryMapping[language];

  const label = language.split('-').join(' | ');

  return (
    <div
      className={styles.languageSwitcherButtonV2}
      onClick={onClick}
    >
      <>
        <FlagIcon
          code={flagCode as FlagIconCode}
          size={16}
          className={styles.flagIcon}
        />
        { label }
      </>
    </div>
  );
});

LanguageSwitcherButtonV2.displayName = 'LanguageSwitcherButtonV2';
