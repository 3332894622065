import React from 'react';

import { LinkPropsWithLoc } from '../LinksSection/LinksSection';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { Typography } from '../Typography/Typography';
import styles from './LinksColumn.module.css';

export type Props = {
  links: Partial<LinkPropsWithLoc>[];
  title?: string | React.ReactNode;
};

export const LinksColumn = React.memo((props: Props) => {
  const {
    links,
    title,
  } = props;

  return (
    <div>
      <div className={styles.liksColumnTitle}>
        <Typography variant='h3'>
          {title}
        </Typography>
      </div>
      {
        links.map((
          { children, ...otherLinkProps }: LinkPropsWithLoc,
          index: number,
        ) =>
          <LocalizedLink
            key={index}
            className={styles.link}
            {...otherLinkProps}
          >
            {children}
          </LocalizedLink>)
      }
    </div>
  );
});

LinksColumn.displayName = 'LinksColumn';
