import React from 'react';

import classNames from 'classnames';

import { imageHooks, ImageSize } from '@nxte-nl/image-hooks';

import { LayoutExtension } from '@customTypes/ResponsiveState';
import { LinkPresentational, ImagePresentational } from '@customTypes/common';
import { dataLayerV2Hooks } from '@hooks/dataLayerV2Hooks/dataLayerV2Hooks';
import { domHooks } from '@hooks/domHooks/domHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

import { AmenityItem } from '../AmenityItem/AmenityItem';
import { Link as LinkComponent, LinkType } from '../Link/Link';
import { Typography } from '../Typography/Typography';
import styles from './BenefitBlock.module.css';

export type BenefitBlockType = {
  button: LinkPresentational;
  description: string;
  image: ImagePresentational;
  imageMobile?: ImagePresentational;
  title: string;
  usps: string[];
};

export type Props = BenefitBlockType & {
  layoutExtension: LayoutExtension | null;
};

export const BenefitBlock = React.memo((props: Props) => {
  const {
    title,
    description,
    image,
    imageMobile,
    usps,
    button,
    layoutExtension,
  } = props;

  const mobImageSrcHref = imageMobile ? imageMobile.url[0].href : image.url[0]?.href;

  const desktopImageHref = imageHooks.useSize(ImageSize.XL, image.url[0]?.href ?? '');
  const mobileImageHref = imageHooks.useSize(ImageSize.M, mobImageSrcHref ?? '');
  const benefitsRedirectId = 'benefits-redirect-button';
  const trackSelectItem = dataLayerV2Hooks.useTrackSelectItem(
    benefitsRedirectId, button.title, button.url,
  );
  const { isMobile } = domHooks.useLayout(layoutExtension);

  const language = navigationHooks.useLanguage();

  return (
    <div className={styles.benefitBlock}>
      <div className={styles.benefitBlockInner}>
        <div className={styles.textWrapper}>
          <Typography
            variant='h2'
            className={classNames(styles.title, styles.dangerousHtml)}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {
            isMobile
              ? <div className={styles.imageWrapper}>
                <img
                  src={mobileImageHref}
                  alt={image.alt || 'Benefit image'}
                  className={styles.image}
                />
              </div> : null
          }
          <Typography
            variant='body4'
            className={classNames(styles.description, styles.dangerousHtml)}
            dangerouslySetInnerHTML={{ __html: description }}
            component='span'
          />

          <div className={styles.usps}>
            {usps.map((usp, id) => (
              <AmenityItem
                key={id}
                className={styles.uspsItem}
                name={usp}
                iconClassNames={{
                  iconCheckClassName: styles.uspsIcon,
                }}
                titleClassName={styles.uspsLabel}
              />
            ))}
          </div>
          <LinkComponent
            id={benefitsRedirectId}
            className={styles.link}
            to={`/${language}${button.url}`}
            type={LinkType.Href}
            onClick={trackSelectItem}
          >
            {button.title}
          </LinkComponent>
        </div>
        {
          !isMobile
            ? <div className={styles.imageWrapper}>
              <img
                src={desktopImageHref}
                alt={image.alt || 'Benefit image'}
                className={styles.image}
              />
            </div> : null
        }
      </div>
    </div>
  );
});

BenefitBlock.displayName = 'BenefitBlock';
