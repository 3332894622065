import React from 'react';

export type Props = {
  className?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const Minus = React.memo((props: Props) => {
  return (
    <svg
      width={props.width || 16}
      height={props.height || 16}
      className={props.className}
      onClick={props.onClick}
      viewBox='0 0 12 4'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M1.17422 0.42334H10.8089C11.4652 0.42334 12.0005 1.13708 12.0005 1.98897C12.0005 2.86398 11.4652 3.57772 10.8089 3.57772H1.17422C0.53532 3.57772 0 2.86398 0 1.98897C0 1.1371 0.535308 0.42334 1.17422 0.42334Z'
        fill='black'
      />
    </svg>
  );
});

Minus.displayName = 'Minus';
