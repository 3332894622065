import React from 'react';

import { FooterV3 } from '@components/FooterV3/FooterV3';
import {
  BreadcrumbKeyType,
} from '@containers/FooterBreadcrumbsContainer/FooterBreadcrumbsContainer';
import {
  LayoutExtensionContainer,
} from '@containers/LayoutExtensionContainer/LayoutExtensionContainer';
import { GlobalMessages } from '@customTypes/Messages';
import { TitleLinksBlockPresentational } from '@customTypes/common';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export type FooterMenuData = {
  activeColumn: string;
  footerMenuContent: FooterMenuContent[];
  setActiveColumn: (column: string) => void;
} | null;

export type FooterMenuContent = {
  links: {
    children: string;
    to: string;
  }[];
  title: string;
};

export const hooks = {
  useFooterMenu: (footerMenu: TitleLinksBlockPresentational[] | null) => {
    const [activeColumn, setActiveColumn] = React.useState<string>('');
    const { blogPage } = messageHooks.useSubjectState(GlobalMessages.FeatureToggles);

    const footerMenuContent: FooterMenuContent[] | undefined = React.useMemo(() =>
      footerMenu?.map(column => ({
        title: column.title,
        links: column.links.map(link => ({
          to: link.url,
          children: link.title,
        })),
      })), [footerMenu]);

    const footerMenuContentWithoutBlog = React.useMemo(() => {
      if (!blogPage || !footerMenuContent) return footerMenuContent;
      return footerMenuContent.map(item => {
        const filteredLinks = item.links.filter(link => !link.to.toLowerCase().includes('/blog'));
        return { ...item, links: filteredLinks };
      });
    }, [blogPage, footerMenuContent]);

    const handleActiveColumn = React.useCallback((column: string) =>
      setActiveColumn(column === activeColumn ? '' : column), [activeColumn]);

    return footerMenuContentWithoutBlog ? {
      footerMenuContent: footerMenuContentWithoutBlog,
      activeColumn,
      setActiveColumn: handleActiveColumn,
    } : null;
  },
};

export const FooterContainerV3 = ({
  breadcrumbKey,
  withoutMargin,
}: {
  breadcrumbKey?: BreadcrumbKeyType;
  withoutMargin?: boolean;
}) => {
  const headerFooterData = messageHooks.useSubjectState(GlobalMessages.HeaderFooterData);
  const deferedHeaderFooterData = React.useDeferredValue(headerFooterData);

  const footerMenuData = hooks
    .useFooterMenu(deferedHeaderFooterData?.data.entity.footerMenus || null);

  return (
    <LayoutExtensionContainer>
      {({ layoutExtension }) => (
        <FooterV3
          layoutExtension={layoutExtension}
          footerMenuData={footerMenuData}
          paymentIcons={deferedHeaderFooterData?.data.entity.paymentIcons || null}
          qualityMarks={deferedHeaderFooterData?.data.entity.qualityMarks || null}
          socialLinks={deferedHeaderFooterData?.data.entity.socialMediaLinks || null}
          breadcrumbKey={breadcrumbKey}
          withoutMargin={withoutMargin}
        />
      )}
    </LayoutExtensionContainer>
  );
};

FooterContainerV3.displayName = 'FooterContainerV3';
