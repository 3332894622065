import React from 'react';

import classnames from 'classnames';

import { ChevronLeft } from '../icons/ChevronLeft/ChevronLeft';
import { ChevronRight } from '../icons/ChevronRight/ChevronRight';
import styles from './CustomSliderControls.module.css';

export type Props = {
  activeIdx: number;
  setActiveIdx: (idx: number) => void;
  totalItems: number;
};

export const hooks = {
  useSideNavigationHandlers(activeIdx: number, setActiveIdx: (idx: number) => void) {
    const handlePrevClick =
      React.useCallback(() => setActiveIdx(activeIdx - 1), [activeIdx, setActiveIdx]);
    const handleNextClick =
      React.useCallback(() => setActiveIdx(activeIdx + 1), [activeIdx, setActiveIdx]);

    return {
      onPrevClick: handlePrevClick,
      onNextClick: handleNextClick,
    };
  },
};

export const CustomSliderControls = React.memo((props: Props) => {
  const {
    activeIdx,
    setActiveIdx,
    totalItems,
  } = props;

  const { onPrevClick, onNextClick } = hooks.useSideNavigationHandlers(activeIdx, setActiveIdx);

  return (
    <div className={styles.customSliderControls}>
      <button
        className={classnames(
          styles.controlButton,
          styles.leftButton,
          { [styles.disabled]: activeIdx === 0 },
        )}
        disabled={activeIdx === 0}
        onClick={onPrevClick}>
        <ChevronLeft width={12} height={8} color='#161616' />
      </button>
      <button
        className={classnames(
          styles.controlButton,
          styles.rightButton,
          { [styles.disabled]: activeIdx === totalItems - 1 },
        )}
        disabled={activeIdx === totalItems - 1}
        onClick={onNextClick}>
        <ChevronRight width={12} height={8} color='#161616' />
      </button>
    </div>
  );
});

CustomSliderControls.displayName = 'CustomSliderControls';
