import React from 'react';

import { IconNameLinkBlockPresentational } from '../../types/common';
import { Link, LinkType } from '../Link/Link';
import { FacebookIcon } from '../icons/FacebookIcon/FacebookIcon';
import { InstagramIcon } from '../icons/InstagramIcon/InstagramIcon';
import { LinkedinIcon } from '../icons/LinkedinIcon/LinkedinIcon';
import { PinterestIcon } from '../icons/PinterestIcon/PinterestIcon';
import { TiktokIcon } from '../icons/TiktokIcon/TiktokIcon';
import { TwitterIcon } from '../icons/TwitterIcon/TwitterIcon';
import styles from './SocialLinks.module.css';

export const hooks = {
  useSocialLinks(socialLinks: IconNameLinkBlockPresentational[]) {
    return React.useMemo(() =>
      socialLinks.map((link) => {
        switch (link.iconName) {
          case 'twitter':
            return (
              <Link
                type={LinkType.ExternalHref}
                to={link.link.url}
                target='_blank'
              >
                <TwitterIcon />
              </Link>
            );
          case 'instagram':
            return (
              <Link
                type={LinkType.ExternalHref}
                to={link.link.url}
                target='_blank'
              >
                <InstagramIcon />
              </Link>
            );
          case 'facebook':
            return (
              <Link
                type={LinkType.ExternalHref}
                to={link.link.url}
                target='_blank'
              >
                <FacebookIcon />
              </Link>
            );
          case 'linkedin':
            return (
              <Link
                type={LinkType.ExternalHref}
                to={link.link.url}
                target='_blank'
              >
                <LinkedinIcon />
              </Link>
            );
          case 'pinterest':
            return (
              <Link
                type={LinkType.ExternalHref}
                to={link.link.url}
                target='_blank'
              >
                <PinterestIcon />
              </Link>
            );
          case 'tiktok':
            return (
              <Link
                type={LinkType.ExternalHref}
                to={link.link.url}
                target='_blank'
              >
                <TiktokIcon />
              </Link>
            );
          default:
            return null;
        }
      }), [socialLinks]);
  },
};

export type Props = {
  links: IconNameLinkBlockPresentational[];
};

export const SocialLinks = React.memo((props: Props) => {
  const {
    links,
  } = props;

  const socialLinksList = hooks.useSocialLinks(links);

  return (
    <div className={styles.socialLinks}>
      {
        socialLinksList.map((link, index) => (
          <div key={index} className={styles.socialLink}>
            {link}
          </div>
        ))
      }
    </div>
  );
});

SocialLinks.displayName = 'SocialLinks';
