import React from 'react';

import { GlobalMessages, Messages } from '@customTypes/Messages';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { AppData } from '@utils/serverUtils/serverUtils';

export const callbacks = {
  useRefs() {
    return React.useRef<boolean>(false);
  },
  setMessageBusValues(values: Messages[GlobalMessages], message: GlobalMessages) {
    const rendered = callbacks.useRefs();

    const setValues = messageHooks.useNext(message);

    if (!rendered.current) {
      setValues(values);
      rendered.current = true;
    }
  },
};

export type Props = {
  appData: AppData;
};

export const ServerSideAppDataContainer = (props: Props) => {
  const { storeData, featureToggles } = props.appData;

  callbacks.setMessageBusValues(storeData, GlobalMessages.StoreData);
  callbacks.setMessageBusValues(featureToggles, GlobalMessages.FeatureToggles);
  return null;
};

ServerSideAppDataContainer.displayName = 'ServerSideAppDataContainer';
