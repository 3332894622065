import React from 'react';

import styles from './NiceSliderPaginationDot.module.css';

export const hooks = {
  useClickHandler(idx: number, onClick: (idx: number) => void) {
    return React.useCallback(() => onClick(idx), [idx, onClick]);
  },
};

export type Props = {
  children?: React.ReactNode;
  idx: number;
  offset: number;
  onClick: (idx: number) => void;
  radius: number;
  view?: 'active' | 'hot' | 'default';
};
export const NiceSliderPaginationDot: React.FC<Props> = React.memo(({
  view = 'default',
  idx,
  radius,
  offset,
  onClick,
}) => {
  const onDotClick = hooks.useClickHandler(idx, onClick);

  const cx = React.useMemo(() =>
    (radius * 2 + offset * 2) * idx + radius + offset, [idx, radius, offset]);

  return (
    <circle
      className={styles[view]}
      cx={cx}
      cy={radius}
      r={radius}
      onClick={onDotClick}
    />
  );
});

NiceSliderPaginationDot.displayName = 'NiceSliderPaginationDot';
