import React from 'react';

export type Props = {
  className?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};

export const Address = React.memo((props: Props) => {
  const {
    width = 59,
    height = 58,
    className,
    onClick,
  } = props;

  const style = {
    minWidth: width,
    minHeight: height,
  };

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
    >
      <g id='Icons/address'>
        <g id='icon'>
          <path id='Stroke 1' d='M45.1052 8.40878C41.2369 4.46029 36.0007 2.24219 30.5408 2.24219H30.5401C25.0805 2.24219 19.8436 4.45956 15.9753 8.40878C12.1073 12.3602 9.92214 17.7203 9.89752 23.3193C9.89752 30.8493 13.3738 38.7806 19.9584 46.2465C22.9359 49.6473 26.2852 52.6855 29.9432 55.3019L30.5408 55.7141L31.1384 55.3019C34.7957 52.6862 38.1453 49.6473 41.1229 46.2465C47.7049 38.7806 51.1834 30.8493 51.1834 23.3193C51.1595 17.7203 48.9739 12.3602 45.1052 8.40878' stroke='white' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round' />
          <path id='Stroke 3' d='M30.5395 29.9311C26.9285 29.9311 24.002 26.9301 24.002 23.2271C24.002 19.5245 26.9285 16.5234 30.5395 16.5234C34.1512 16.5234 37.0777 19.5245 37.0777 23.2271C37.0777 26.9301 34.1512 29.9311 30.5395 29.9311' stroke='white' strokeWidth='1.8' />
          <path id='Stroke 5' d='M19.9585 46.2459C22.936 49.6463 26.2853 52.6849 29.943 55.3013L30.5406 55.7135L31.1382 55.3013C34.7955 52.6856 38.1455 49.6463 41.123 46.2459C47.7046 38.78 51.1831 30.8488 51.1831 23.3184C51.1592 17.7198 48.974 12.3593 45.1054 8.4082' stroke='white' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round' />
          <path id='Stroke 7' d='M45.1048 8.40878C41.2364 4.46029 36.0002 2.24219 30.5403 2.24219H30.5396' stroke='white' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round' />
          <path id='Fill 9' fillRule='evenodd' clipRule='evenodd' d='M22.1761 46.2729C22.1761 47.5112 21.1891 48.5146 19.9718 48.5146C18.7541 48.5146 17.767 47.5112 17.767 46.2729C17.767 45.0347 18.7541 44.0312 19.9718 44.0312C21.1891 44.0312 22.1761 45.0347 22.1761 46.2729Z' fill='white' />
          <g id='Group 13'>
            <mask
              id='mask0_11860_40234'
              style={{ 'maskType': 'luminance' }}
              maskUnits='userSpaceOnUse'
              x='28' y='0' width='5' height='5'
            >
              <path id='Clip 12' fillRule='evenodd' clipRule='evenodd' d='M28.3361 0H32.7452V4.48351H28.3361V0Z' fill='white' />
            </mask>
            <g mask='url(#mask0_11860_40234)'>
              <path id='Fill 11' fillRule='evenodd' clipRule='evenodd' d='M32.7452 2.24203C32.7452 3.47991 31.7581 4.48369 30.5409 4.48369C29.3232 4.48369 28.3361 3.47991 28.3361 2.24203C28.3361 1.00378 29.3232 0 30.5409 0C31.7581 0 32.7452 1.00378 32.7452 2.24203Z' fill='white' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
});

Address.displayName = 'Address';
