import React from 'react';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { AccountSwitcher } from '@components/AccountSwitcher/AccountSwitcher';
import {
  LayoutExtensionContainer,
} from '@containers/LayoutExtensionContainer/LayoutExtensionContainer';
import { AccountMessages, GlobalMessages } from '@customTypes/Messages';
import { accountHooks } from '@hooks/accountHooks/accountHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export type Props = {
  hideMobileMenu: () => void;
};

export const AccountSwitcherSidebarContainer = (props: Props) => {
  const { hideMobileMenu } = props;

  const userInfoData = messageHooks.useSubjectState(AccountMessages.UserInfo);
  const { accountRelease1,
    accountRelease2,
    accountRelease3,
    accountRelease4 } = messageHooks.useSubjectState(GlobalMessages.FeatureToggles);

  const { onLogOut } = accountHooks.useLogOut();

  return (
    <>
      <ErrorBoundary>
        <LayoutExtensionContainer>
          {({ layoutExtension }) => (
            <AccountSwitcher
              layoutExtension={layoutExtension}
              loggedIn={!userInfoData?.data.errors_v1?.length && !!userInfoData?.data.user}
              onLogOut={onLogOut}
              accountRelease1={accountRelease1}
              accountRelease2={accountRelease2}
              accountRelease3={accountRelease3}
              accountRelease4={accountRelease4}
              isSidebarMenu={true}
              userName={null}
              hideMobileMenu={hideMobileMenu}
            />
          )}
        </LayoutExtensionContainer>
      </ErrorBoundary>
    </>
  );
};

AccountSwitcherSidebarContainer.displayName = 'AccountSwitcherSidebarContainer';
