import React from 'react';

import { LayoutExtension } from '@customTypes/ResponsiveState';
import { ImagePresentational, LinkPresentational } from '@customTypes/common';

import { CustomSlider } from '../CustomSlider/CustomSlider';
import { RegionSlide } from '../RegionSlide/RegionSlide';

export type TitleImageLinkBlockPresentational = {
  image: ImagePresentational;
  link: LinkPresentational;
  title: string;
};

export type Props = {
  description: string;
  isDesktop: boolean;
  items: TitleImageLinkBlockPresentational[];
  layoutExtension: LayoutExtension;
  mobileTextAlignLeft?: boolean;
  reversed?: boolean;
  sliderId: string;
  title: string;
};

const SL_WIDTH = {
  [LayoutExtension.DesktopLarge]: 400,
  [LayoutExtension.DesktopSmall]: 400,
  [LayoutExtension.Tablet]: 264,
  [LayoutExtension.Mobile]: 300,
};
const SL_MARGIN = {
  [LayoutExtension.DesktopLarge]: 20,
  [LayoutExtension.DesktopSmall]: 20,
  [LayoutExtension.Tablet]: 8,
  [LayoutExtension.Mobile]: 8,
};

export const callbacks = {
  renderSlides(sliderId: string, items: TitleImageLinkBlockPresentational[]) {
    return (activeIdx: number, extended: boolean) => {
      return items.map((slide, idx) => (
        <RegionSlide
          key={`${sliderId}_region_slide_${idx}`}
          active={extended && idx === activeIdx}
          image={slide.image}
          url={slide.link.url}
          title={slide.title}
          extended={extended}
        />
      ));
    };
  },
};

export const RegionsSlider = React.memo((props: Props) => {
  const {
    reversed,
    items,
    title,
    description,
    layoutExtension,
    isDesktop,
    sliderId,
    mobileTextAlignLeft,
  } = props;

  return (
    <CustomSlider
      reversed={reversed}
      title={title}
      totalItems={items.length}
      description={description}
      isDesktop={isDesktop}
      sliderId={sliderId}
      slideSpacing={
        SL_WIDTH[layoutExtension] + (SL_MARGIN[layoutExtension] * 2)}
      mobileTextAlignLeft={mobileTextAlignLeft}
    >
      {
        callbacks.renderSlides(sliderId, items)
      }
    </CustomSlider>
  );
});

RegionsSlider.displayName = 'RegionsSlider';
