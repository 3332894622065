import React from 'react';

import classNames from 'classnames';

import { PageWrapperWithHeaderFooter }
  from '@components/PageWrapperWithHeaderFooter/PageWrapperWithHeaderFooter';
import { ThrobberWrapper } from '@components/ThrobberWrapper/ThrobberWrapper';
import { HeroBannerV2Container } from '@containers/HeroBannerV2Container/HeroBannerV2Container';
import { HomePageV2HeaderBodyContainer }
  from '@containers/HomePageV2HeaderBodyContainer/HomePageV2HeaderBodyContainer';
import { UspBannerContainer } from '@containers/UspBannerContainer/UspBannerContainer';
import { FieldWeightsPresentational, Size } from '@customTypes/common';
import desktopWebp1x from '@images/banner_desktop1x.webp';
import desktopJpg2x from '@images/banner_desktop2x.jpg';
import desktopWebp2x from '@images/banner_desktop2x.webp';
import mobileWebp1x from '@images/banner_mobile1x.webp';
import mobileWebp2x from '@images/banner_mobile2x.webp';

import styles from './HomePageV2.module.css';

export enum BlockName {
  BrandsFirstRow = 'brandsFirstRow',
  BusinessBlock = 'businessBlock',
  ContentBlockFirst = 'contentBlockFirst',
  ContentBlockSecond = 'contentBlockSecond',
  RegionsBlockFirst = 'regionsBlockFirst',
  RegionsBlockSecond = 'regionsBlockSecond',
  ShowTrustpilotReviews = 'showTrustpilotReviews',
}

export const defaultWeights: FieldWeightsPresentational[] = [
  {
    value: BlockName.BrandsFirstRow,
    weight: 0,
  },
  {
    value: BlockName.ContentBlockFirst,
    weight: 1,
  },
  {
    value: BlockName.BusinessBlock,
    weight: 2,
  },
  {
    value: BlockName.RegionsBlockFirst,
    weight: 3,
  },
  {
    value: BlockName.RegionsBlockSecond,
    weight: 4,
  },
  {
    value: BlockName.ShowTrustpilotReviews,
    weight: 5,
  },
  {
    value: BlockName.ContentBlockSecond,
    weight: 6,
  },
];

export const hooks = {
  useSortingByWeights(
    props: Omit<Props,
    'loading'
    | 'desktopHeroImageUrl'
    | 'mobileHeroImageUrl'
    | 'desktopSize'
    | 'mobileSize'
    | 'usps'
    >,
  ) {
    const {
      brandLogosBlock,
      contentBlockFirst,
      businessBlock,
      contentBlockSecond,
      firstRegionsSlider,
      secondRegionsSlider,
      trustpilotBlock,
      weights,
    } = props;

    const filteredWeights = weights?.filter(item =>
      ['V2', 'heroBlock'].every(substring => !item?.value.includes(substring)));

    return React.useMemo(() => ((filteredWeights || defaultWeights).map((block, i) => {
      switch (block?.value) {
        case BlockName.BrandsFirstRow:
          return brandLogosBlock ? (
            <div
              key={i}
              id={BlockName.BrandsFirstRow}
              className={classNames(styles.brandLogosBlockGap, {
                [styles.blockFirst]: i === 0,
              })}
            >
              {brandLogosBlock}
            </div>
          ) : null;
        case BlockName.ContentBlockFirst:
          return contentBlockFirst ? (
            <div
              key={i}
              id={BlockName.ContentBlockFirst}
              className={
                classNames(styles.row, styles.contentBlockFirstGap, {
                  [styles.blockFirst]: i === 0,
                })
              }
            >
              {contentBlockFirst}
            </div>
          ) : null;
        case BlockName.BusinessBlock:
          return businessBlock ? (
            <div
              key={i}
              id={BlockName.BusinessBlock}
              className={
                classNames(styles.row, styles.businessBlockGap, {
                  [styles.blockFirst]: i === 0,
                })
              }
            >
              {businessBlock}
            </div>
          ) : null;
        case BlockName.RegionsBlockFirst:
          return firstRegionsSlider ? (
            <div
              key={i}
              id={BlockName.RegionsBlockFirst}
              className={
                classNames(styles.row, styles.firstRegionsSlider, {
                  [styles.blockFirst]: i === 0,
                })
              }
            >
              {firstRegionsSlider}
            </div>
          ) : null;
        case BlockName.RegionsBlockSecond:
          return secondRegionsSlider ? (
            <div
              key={i}
              id={BlockName.RegionsBlockSecond}
              className={classNames(styles.row, styles.secondRegionsSlider, {
                [styles.blockFirst]: i === 0,
              })}
            >
              {secondRegionsSlider}
            </div>
          ) : null;
        case BlockName.ShowTrustpilotReviews:
          return trustpilotBlock ? (
            <div
              key={i}
              id={BlockName.ShowTrustpilotReviews}
              className={classNames(styles.row, styles.trustpilotBlock, {
                [styles.blockFirst]: i === 0,
              })}
            >
              {trustpilotBlock}
            </div>
          ) : null;
        case BlockName.ContentBlockSecond:
          return contentBlockSecond ? (
            <div
              key={i}
              id={BlockName.ContentBlockSecond}
              className={classNames(styles.row, styles.contentBlockSecondGap, {
                [styles.blockFirst]: i === 0,
              })}
            >
              {contentBlockSecond}
            </div>
          ) : null;
      }
      return null;
    })), [
      brandLogosBlock,
      contentBlockFirst,
      businessBlock,
      contentBlockSecond,
      firstRegionsSlider,
      secondRegionsSlider,
      trustpilotBlock,
      filteredWeights,
    ]);
  },
};

export type Props = {
  brandLogosBlock: JSX.Element | null;
  businessBlock: JSX.Element | null;
  contentBlockFirst: JSX.Element | null;
  contentBlockSecond: JSX.Element | null;
  desktopHeroImageUrl?: string;
  desktopSize: Size;
  firstRegionsSlider: JSX.Element | null;
  loading: boolean;
  mobileHeroImageUrl?: string;
  mobileSize: Size;
  secondRegionsSlider: JSX.Element | null;
  trustpilotBlock: JSX.Element | null;
  usps: string[] | null;
  weights?: (FieldWeightsPresentational | null)[] | null;
};

export const HomePageV2 = React.memo((props: Props) => {
  const {
    loading,
    desktopHeroImageUrl,
    mobileHeroImageUrl,
    usps,
    ...rest
  } = props;

  const blocks = hooks.useSortingByWeights(rest);

  return (
    <PageWrapperWithHeaderFooter>
      <div className={styles.contentContainer}>
        {loading
          ? <ThrobberWrapper
            throbberWrapperClassName={styles.loadingState}
          />
          : (
            <>
              <div className={styles.homePageHeroBlock}>
                <HeroBannerV2Container
                  className={styles.homePageHeroBanner}
                  dynamicDesktopImage={desktopHeroImageUrl}
                  dynamicMobileImage={mobileHeroImageUrl}
                  desktopWebp={[desktopWebp1x, desktopWebp2x]}
                  mobileWebp={[mobileWebp1x, mobileWebp2x]}
                  desktopJpg={desktopJpg2x}
                  dynamicImg={!!desktopHeroImageUrl && !!mobileHeroImageUrl} />
                <div className={styles.headerBodyWrapper}>
                  <HomePageV2HeaderBodyContainer />
                </div>
                {usps
                  ? <div className={styles.uspsContainerV2}>
                    <UspBannerContainer usps={usps} withAnimation={false} />
                  </div>
                  : null}
              </div>
              <div>
                {blocks}
              </div>
            </>
          )
        }
      </div>
    </PageWrapperWithHeaderFooter>
  );
});

HomePageV2.displayName = 'HomePageV2';
