import React from 'react';

export type Props = {
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};

export const InstagramIcon = React.memo((props: Props) => {
  const {
    width = 24,
    height = 25,
    className,
    onClick,
  } = props;

  const style = {
    minWidth: width,
    minHeight: height,
  };

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path d='M17.0084 21.5049L7.00837 21.5144C4.80837 21.5164 3.00687 19.7184 3.00437 17.5184L2.99487 7.51838C2.99287 5.31838 4.79087 3.51688 6.99088 3.51438L16.9909 3.50488C19.1909 3.50288 20.9924 5.30088 20.9949 7.50088L21.0044 17.5009C21.0069 19.7014 19.2084 21.5029 17.0084 21.5049Z' fill='#161616'/>
      <path d='M11.9995 16.0098C10.07 16.0098 8.49951 14.4398 8.49951 12.5098C8.49951 10.5798 10.07 9.00977 11.9995 9.00977C13.929 9.00977 15.4995 10.5798 15.4995 12.5098C15.4995 14.4398 13.929 16.0098 11.9995 16.0098ZM11.9995 10.0098C10.621 10.0098 9.49951 11.1313 9.49951 12.5098C9.49951 13.8883 10.621 15.0098 11.9995 15.0098C13.378 15.0098 14.4995 13.8883 14.4995 12.5098C14.4995 11.1313 13.378 10.0098 11.9995 10.0098Z' fill='white'/>
      <path d='M15.7498 9.50977C16.164 9.50977 16.4998 9.17398 16.4998 8.75977C16.4998 8.34555 16.164 8.00977 15.7498 8.00977C15.3355 8.00977 14.9998 8.34555 14.9998 8.75977C14.9998 9.17398 15.3355 9.50977 15.7498 9.50977Z' fill='white'/>
      <path d='M14.9998 19.0098H8.99976C7.07026 19.0098 5.49976 17.4398 5.49976 15.5098V9.50977C5.49976 7.57977 7.07026 6.00977 8.99976 6.00977H14.9998C16.9293 6.00977 18.4998 7.57977 18.4998 9.50977V15.5098C18.4998 17.4398 16.9293 19.0098 14.9998 19.0098ZM8.99976 7.00977C7.62126 7.00977 6.49976 8.13127 6.49976 9.50977V15.5098C6.49976 16.8883 7.62126 18.0098 8.99976 18.0098H14.9998C16.3783 18.0098 17.4998 16.8883 17.4998 15.5098V9.50977C17.4998 8.13127 16.3783 7.00977 14.9998 7.00977H8.99976Z' fill='white'/>
    </svg>
  );
});

InstagramIcon.displayName = 'InstagramIcon';
