import React from 'react';

import classnames from 'classnames';

import { StaticImage } from '@nxte-nl/static-image';

import {
  AccountSwitcherSidebarContainer,
} from '@containers/AccountSwitcherSidebarContainer/AccountSwitcherSidebarContainer';
import { FeatureToggleHocContainer }
  from '@containers/FeatureToggleHocContainer/FeatureToggleHocContainer';
import { SubmenuType }
  from '@containers/HeaderContainerV3/HeaderContainerV3';

import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { SectionExpandable } from '../SectionExpandable/SectionExpandable';
import styles from './MobileMenuV3.module.css';

export type Props = {
  businessMenuProps: {
    items: JSX.Element[];
    redirect: string;
    title: JSX.Element;
  } | null;
  clientServiceMenuProps: {
    items: JSX.Element[];
    redirect: string;
    title: JSX.Element;
  } | null;
  hideMobileMenu: () => void;
  submenu: SubmenuType[] | null;
};

export const MobileMenuV3 = React.memo((props: Props) => {
  const {
    submenu,
    businessMenuProps,
    clientServiceMenuProps,
    hideMobileMenu,
  } = props;

  return (
    <div className={styles.mobileMenuV3}>
      {submenu
        ? submenu.map((el, i) => (
          <LocalizedLink
            key={i}
            to={el.link.url}
            className={classnames(styles.submenuMobileLink, {
              [styles.isActive]: el.isActive,
            })}
          >
            {el.image?.url[0].href
              && <StaticImage
                src={el.image.url[0].href}
                alt={el.image.alt ?? ''}
                className={styles.submenuMobileIcon}
              />
            }
            {el.link.title}
          </LocalizedLink>
        ))
        : null
      }
      {businessMenuProps && (
        <SectionExpandable
          title={businessMenuProps.title}
          titleRedirect={businessMenuProps.redirect}
          items={businessMenuProps.items}
        />
      )}
      {clientServiceMenuProps && (
        <SectionExpandable
          title={clientServiceMenuProps.title}
          titleRedirect={clientServiceMenuProps.redirect}
          items={clientServiceMenuProps.items}
        />
      )}
      <FeatureToggleHocContainer>
        {({ accountRelease1 }) => accountRelease1
          ? <AccountSwitcherSidebarContainer hideMobileMenu={hideMobileMenu} />
          : null
        }
      </FeatureToggleHocContainer>
    </div>
  );
});

MobileMenuV3.displayName = 'MobileMenuV3';
