import React from 'react';

import Head from 'next/head';

import { prerenderedCallbacks } from '@callbacks/prerenderedCallbacks/prerenderedCallbacks';
import { clientFactory } from 'src/apolloClient/apolloClient';

import { NextPageWrapper } from '@components/NextPageWrapper/NextPageWrapper';
import {
  EntityMetadataContainer,
} from '@containers/EntityMetadataContainer/EntityMetadataContainer';
import HomePageV2Container from '@containers/HomePageV2Container/HomePageV2Container';
import {
  LayoutExtensionContainer,
} from '@containers/LayoutExtensionContainer/LayoutExtensionContainer';
import {
  ServerSideAppDataContainer,
} from '@containers/ServerSideAppDataContainer/ServerSideAppDataContainer';
import { Entity } from '@customTypes/Entities';
import { HeaderFooterData } from '@customTypes/apiCompound';
import { HGC_HomePage_v2 } from '@customTypes/apiTypes';
import { BaseResponse, LanguageCodeMapped } from '@customTypes/common';
import { layoutExtensionMapping, RenderingLayout } from '@customTypes/nextTypes';
import { isResultFulfilled } from '@customTypes/typeGuards';
import { homePageV2Entity } from '@queries/entities/homePageV2Entity.gql';
import { AppData, PageRootComponent, serverUtils } from '@utils/serverUtils/serverUtils';

export type Props = {
  appData: AppData;
  entity: BaseResponse<Entity<HGC_HomePage_v2>> | null;
  headerFooterData?: BaseResponse<HeaderFooterData> | null;
  language: LanguageCodeMapped | null;
  messages: Record<string, string>;
  renderingLayout: RenderingLayout;
};

const HomePagePrerendered = (props: Props) => {
  const { appData, language, renderingLayout, entity, headerFooterData, messages } = props;

  const layoutExtension = layoutExtensionMapping[renderingLayout];

  prerenderedCallbacks.setMessageValues({
    layoutExtension,
    language,
    entity,
    headerFooterData,
  });

  return (
    <NextPageWrapper language={language ?? LanguageCodeMapped.EnGb} messages={messages}>
      <>
        {CONFIG.isLive
        && (language === LanguageCodeMapped.FrFr || language === LanguageCodeMapped.EnFr) ? (
            <Head>
              <script
                type='text/javascript'
                dangerouslySetInnerHTML={{ __html: `
                (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/+i";
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "m7pi9x5ytp");
              ` }}
              />
            </Head>
          ) : null}

        <ServerSideAppDataContainer appData={appData} />
        <EntityMetadataContainer />
        <LayoutExtensionContainer>
          {({ layoutExtension }) => (
            <HomePageV2Container layoutExtension={layoutExtension}/>
          )}
        </LayoutExtensionContainer>
      </>
    </NextPageWrapper>
  );
};

export function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

export async function getStaticProps({ locale: rawLocale, params: { layout } }: {
  locale: `${LanguageCodeMapped}` | 'default'; params: { layout: RenderingLayout };
}) {
  const locale = rawLocale === 'default'
    ? LanguageCodeMapped.NlNl : rawLocale as LanguageCodeMapped;
  const client = clientFactory.createClient(locale, 'homepagePrerendered');

  const appDataPromise = serverUtils.appDataFetch(client);
  const entityResponsePromise = client.query({
    query: homePageV2Entity,
    variables: {
      url: '/',
      entityBundle: 'hgc_homepage_v2',
      entityType: 'node',
    },
  });

  const headerFooterDataPromise = serverUtils.headerFooterDataFetch(client);

  const [appDataResponse, entityResponse, headerFooterDataResponse] = await Promise.allSettled(
    [appDataPromise, entityResponsePromise, headerFooterDataPromise],
  );

  const filteredTranslations = isResultFulfilled(appDataResponse)
    ? appDataResponse.value.featureToggles.filteredTranslations : undefined;
  const messages = await serverUtils
    .getMessages(locale, PageRootComponent.HomePageV2Container, filteredTranslations);

  serverUtils.throwIfErrorPresent({
    appData: { response: appDataResponse, dataPath: ['storeData', 'data']},
    entityData: { response: entityResponse, dataPath: ['data', 'data' ]},
    headerFooterData: { response: headerFooterDataResponse, dataPath: ['data']},
  });

  return {
    props: {
      appData: isResultFulfilled(appDataResponse) ? appDataResponse.value : null,
      entity: isResultFulfilled(entityResponse) ? entityResponse.value.data : null,
      headerFooterData: isResultFulfilled(headerFooterDataResponse)
        ? headerFooterDataResponse.value : null,
      renderingLayout: layout,
      language: locale,
      messages,
    },
  };
}

export default HomePagePrerendered;
