import React from 'react';

import { FloatingTree } from '@floating-ui/react';

import { BreadcrumbKeyType }
  from '@containers/FooterBreadcrumbsContainer/FooterBreadcrumbsContainer';
import { FooterContainerV3 } from '@containers/FooterContainerV3/FooterContainerV3';
import { HeaderContainerV3 } from '@containers/HeaderContainerV3/HeaderContainerV3';

export type Props = {
  breadcrumbKey?: BreadcrumbKeyType;
  children?: React.ReactNode;
  withoutMargin?: boolean;
};

export const HeaderFooterContainer: React.FC<Props> = React.memo((props) => {
  const {
    children,
    breadcrumbKey,
    withoutMargin,
  } = props;
  return (
    <>
      <FloatingTree><HeaderContainerV3 /></FloatingTree>

      {children}

      <FooterContainerV3
        withoutMargin={withoutMargin}
        breadcrumbKey={breadcrumbKey}
      />
    </>
  );
});

HeaderFooterContainer.displayName = 'HeaderFooterContainer';
