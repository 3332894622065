import React from 'react';

export type Props = {
  className?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};

export const Newsletter = React.memo((props: Props) => {
  const {
    width = 54,
    height = 46,
    className,
    onClick,
  } = props;

  const style = {
    minWidth: width,
    minHeight: height,
  };

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
    >
      <path fillRule='evenodd' clipRule='evenodd' d='M11.3705 15.6639C11.3999 18.475 9.25823 20.7747 6.61193 20.7747C3.96533 20.7747 1.79993 23.0744 1.79993 25.8858V38.8013C1.79993 41.6127 4.09523 43.9124 6.89993 43.9124H46.8017C49.6067 43.9124 51.9017 41.6127 51.9017 38.8013V6.94314C51.9017 4.13233 49.6067 1.83203 46.8017 1.83203H16.3268C13.5215 1.83203 11.2505 4.13233 11.2799 6.94284L11.3705 15.6639Z' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M31.5331 43.9124H46.8016C49.6066 43.9124 51.9016 41.6127 51.9016 38.8013V6.94314C51.9016 4.13233 49.6066 1.83203 46.8016 1.83203H20.2888' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.357 14.3672V38.5214C11.357 38.5214 11.2211 43.9121 6.5105 43.9121' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M35.6001 11.0195H43.8891' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M35.6001 18.3417H43.8891' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19.1774 26.8749H43.889' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19.1774 34.1972H43.889' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <mask
        id='mask0_12751_2884'
        style={{ 'maskType': 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='0' y='0' width='54' height='46'
      >
        <path fillRule='evenodd' clipRule='evenodd' d='M0 45.7426H53.7018V0H0V45.7426Z' fill='white' />
      </mask>
      <g mask='url(#mask0_12751_2884)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M19.1602 18.3416H28.9939V11.0195H19.1602V18.3416Z' stroke='white' strokeWidth='2' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M22.1158 1.83098C22.1158 2.84238 21.2977 3.66226 20.2888 3.66226C19.2796 3.66226 18.4615 2.84238 18.4615 1.83098C18.4615 0.819882 19.2796 0 20.2888 0C21.2977 0 22.1158 0.819882 22.1158 1.83098Z' fill='white' />
        <path fillRule='evenodd' clipRule='evenodd' d='M33.3604 43.9111C33.3604 44.9225 32.5423 45.7423 31.5334 45.7423C30.5242 45.7423 29.7061 44.9225 29.7061 43.9111C29.7061 42.9 30.5242 42.0801 31.5334 42.0801C32.5423 42.0801 33.3604 42.9 33.3604 43.9111Z' fill='white' />
      </g>
    </svg>
  );
});

Newsletter.displayName = 'Newsletter';
