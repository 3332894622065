import React from 'react';

import { Size } from '@customTypes/common';

import customMedia from '../../media/media.json';
import styles from './DynamicPicture.module.css';

export type Props = {
  dynamicDesktopImage?: string;
  dynamicMobileImage?: string;
  size?: Size;
};
export const DynamicPicture = React.memo((props: Props) => {
  const { dynamicDesktopImage, dynamicMobileImage, size } = props;

  return (
    <>
      {dynamicDesktopImage || dynamicMobileImage ? (
        <picture>
          <source
            srcSet={`${dynamicDesktopImage} 2880w`}
            media={customMedia['custom-media']['--desktop-large']}
            type='image/webp'
          />
          <source
            srcSet={`${dynamicDesktopImage} 1440w`}
            media={
              `${customMedia['custom-media']['--tablet']}, 
               ${customMedia['custom-media']['--desktop-small']}`
            }
            type='image/webp'
          />
          <source
            srcSet={dynamicMobileImage ? `${encodeURI(dynamicMobileImage)} 320w,
            ${encodeURI(dynamicMobileImage)} 640w` : ''}
            media={customMedia['custom-media']['--mobile']}
            type='image/webp'
          />

          <img
            src={dynamicDesktopImage}
            alt='Hero Banner'
            className={styles.image}
            height={size ? size.height : '100%'}
          />
        </picture>
      ) : (
        <div
          className={styles.backgroundImage}
          style={size && { ...size }}
        />
      )}
    </>
  );
});

DynamicPicture.displayName = 'DynamicPicture';
