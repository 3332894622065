import React from 'react';

import { HeroBannerV2 } from '@components/HeroBannerV2/HeroBannerV2';
import { Size } from '@customTypes/common';

import { LayoutExtensionContainer } from '../LayoutExtensionContainer/LayoutExtensionContainer';

export type Props = {
  className?: string;
  desktopJpg: string;
  desktopSize?: Size;
  desktopWebp: [string, string];
  dynamicDesktopImage?: string;
  dynamicImg?: boolean;
  dynamicMobileImage?: string;
  mobileSize?: Size;
  mobileWebp: [string, string];
};

export const HeroBannerV2Container = React.memo((props: Props) => {
  const {
    desktopWebp,
    mobileWebp,
    desktopJpg,
    mobileSize,
    desktopSize,
    className,
    dynamicImg,
    dynamicDesktopImage,
    dynamicMobileImage,
  } = props;

  return (
    <LayoutExtensionContainer>
      {({ isMobile }) => (
        <HeroBannerV2
          desktopWebp={desktopWebp}
          mobileWebp={mobileWebp}
          desktopJpg={desktopJpg}
          size={isMobile ? mobileSize : desktopSize}
          className={className}
          dynamicImg={dynamicImg}
          dynamicDesktopImage={dynamicDesktopImage}
          dynamicMobileImage={dynamicMobileImage}
        />
      )}
    </LayoutExtensionContainer>
  );
});

HeroBannerV2Container.displayName = 'HeroBannerV2Container';
