import React from 'react';

export type Props = {
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const ChevronLeft = React.memo((props: Props) => {
  const color = props.color || '#1c1c1c';
  return (
    <svg
      width={props.width || 16}
      height={props.height || 16}
      className={props.className}
      onClick={props.onClick}
      viewBox='0 0 6 10'
    >
      <g id='icon/chevron-left' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path d='M3.08972925,7.99568105 C3.28635658,7.97400454 3.47253336,7.88562945 3.62205665,7.74222644 L7.64149983,3.90038812 C7.84411805,3.71946742 7.97197462,3.45435069 7.99589988,3.16670372 C8.01982514,2.87905675 7.93832997,2.59309592 7.77010287,2.37297993 C7.60113122,2.15371768 7.36038763,2.01948811 7.10168861,2.00196506 C6.84298959,1.98445695 6.58878852,2.08450482 6.39738647,2.27960191 L3,5.52787621 L-0.397386532,2.27960191 C-0.588788589,2.08450909 -0.84298966,1.98446335 -1.10168868,2.00196506 C-1.3603877,2.01947317 -1.6011332,2.15370487 -1.77010294,2.37297993 C-1.93832812,2.59307457 -2.0198252,2.87905675 -1.99589995,3.16670372 C-1.97197469,3.45435069 -1.84412386,3.71945888 -1.6414999,3.90038812 L2.37794328,7.74222644 C2.57457062,7.9306494 2.8317767,8.02152808 3.08972925,7.99568105 Z' id='Fill-1' fill={color} transform='translate(3.000000, 5.000000) rotate(-270.000000) translate(-3.000000, -5.000000) '></path>
      </g>
    </svg>
  );
});
