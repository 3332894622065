import React from 'react';

export type Props = {
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};

export const TiktokIcon = React.memo((props: Props) => {
  const {
    width = 24,
    height = 25,
    className,
    onClick,
  } = props;

  const style = {
    minWidth: width,
    minHeight: height,
  };

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path fillRule='evenodd' clipRule='evenodd' d='M11.9999 22.8003C17.6713 22.8003 22.2689 18.1932 22.2689 12.51C22.2689 6.82685 17.6713 2.21973 11.9999 2.21973C6.32853 2.21973 1.73096 6.82685 1.73096 12.51C1.73096 18.1932 6.32853 22.8003 11.9999 22.8003ZM14.0968 6.25416C13.9793 5.90614 13.6271 5.69439 13.2646 5.7539C12.9022 5.81342 12.6362 6.12668 12.6362 6.49399V15.518C12.6362 16.7564 11.6172 17.776 10.3406 17.776C9.06405 17.776 8.0451 16.7564 8.0451 15.518C8.0451 14.2797 9.06405 13.26 10.3406 13.26C10.7548 13.26 11.0906 12.9242 11.0906 12.51C11.0906 12.0958 10.7548 11.76 10.3406 11.76C8.25321 11.76 6.5451 13.4338 6.5451 15.518C6.5451 17.6023 8.25321 19.276 10.3406 19.276C12.4281 19.276 14.1362 17.6023 14.1362 15.518V8.93932C14.9165 9.66769 16.0069 10.252 17.4469 10.252C17.8611 10.252 18.1969 9.91622 18.1969 9.50201C18.1969 9.08779 17.8611 8.75201 17.4469 8.75201C15.4779 8.75201 14.3882 7.11766 14.0968 6.25416Z' fill='#161616'/>
    </svg>
  );
});

TiktokIcon.displayName = 'TiktokIcon';
