import React from 'react';

export type Props = {
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};

export const PinterestIcon = React.memo((props: Props) => {
  const {
    width = 24,
    height = 25,
    className,
    onClick,
  } = props;

  const style = {
    minWidth: width,
    minHeight: height,
  };

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path d='M12 2.50977C6.48277 2.50977 2 6.98484 2 12.4926C2 16.589 4.44828 20.1002 8 21.6493C7.96551 20.9608 8 20.1002 8.17242 19.3429C8.37934 18.5167 9.44828 13.9039 9.44828 13.9039C9.44828 13.9039 9.13793 13.2499 9.13793 12.3205C9.13793 10.8402 10 9.73869 11.0689 9.73869C11.9655 9.73869 12.4138 10.4272 12.4138 11.2533C12.4138 12.1483 11.8276 13.5253 11.5172 14.7989C11.2759 15.8661 12.0345 16.7266 13.1034 16.7266C15 16.7266 16.2759 14.2826 16.2759 11.4254C16.2759 9.22228 14.7931 7.6044 12.1034 7.6044C9.06895 7.6044 7.17242 9.87635 7.17242 12.3893C7.17242 13.2498 7.41379 13.8695 7.82758 14.3514C8 14.5579 8.03449 14.6612 7.96551 14.9022C7.93102 15.0743 7.79309 15.5218 7.75859 15.6939C7.68961 15.9348 7.48274 16.0381 7.24137 15.9348C5.82758 15.3496 5.20688 13.835 5.20688 12.1138C5.20688 9.2911 7.58617 5.88318 12.3448 5.88318C16.1724 5.88318 18.6896 8.63707 18.6896 11.5975C18.6896 15.5218 16.5172 18.4478 13.3104 18.4478C12.2414 18.4478 11.2069 17.8626 10.8621 17.2085C10.8621 17.2085 10.2759 19.5149 10.1724 19.9624C9.96551 20.7198 9.55172 21.5115 9.17242 22.0967C10.069 22.3721 11.0345 22.5098 12 22.5098C17.5172 22.5098 22 18.0347 22 12.5269C22 7.01919 17.5173 2.50977 12 2.50977Z' fill='#161616'/>
    </svg>
  );
});

PinterestIcon.displayName = 'PinterestIcon';
